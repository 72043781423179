import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@sentry/angular';

@Pipe({
  name: 'pipeUserGeneric',
})
export class pipeUserGeneric implements PipeTransform {
  constructor() {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const user = value as User;
    return !user.nomContact && !user.prenomContact ? true : false;
  }
}
