/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Evenement } from './evenement';
import { Utilisateur } from './utilisateur';

export interface Document { 
    id?: number;
    nom?: string;
    contenu?: string;
    diffusion?: string;
    emetteur?: Utilisateur;
    dateEnvoi?: string;
    evenement?: Evenement;
}