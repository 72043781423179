/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DiffusionDocument = 'interne' | 'presta' | 'public';

export const DiffusionDocument = {
    Interne: 'interne' as DiffusionDocument,
    Presta: 'presta' as DiffusionDocument,
    Public: 'public' as DiffusionDocument
};