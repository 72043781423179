/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TypeNotification = 'status' | 'comment' | 'remind' | 'other';

export const TypeNotification = {
    Status: 'status' as TypeNotification,
    Comment: 'comment' as TypeNotification,
    Remind: 'remind' as TypeNotification,
    Other: 'other' as TypeNotification
};