import { Injectable } from '@angular/core';
import { EventHelperConfig } from './eventHelperConfig';
import { AuthentificationService, Evenement, EvenementsService, Utilisateur, UtilisateursService } from './server';

const PARAM_CONFIG = "EventHelper_Config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private svcEvt: EvenementsService,
    private svcAuth: AuthentificationService,
    private svcUsr: UtilisateursService) {

  }

  async getConfig(): Promise<EventHelperConfig> {
    let configLocale: EventHelperConfig = undefined;
    // console.log("--- getConfig ---");
    let token = localStorage.getItem("Token");
    if (!token) {
      let lang = undefined;
      if (navigator.language) {
        lang = navigator.language.substring(0, 2);
        // localStorage.setItem("Language", lang);
      }
      configLocale = { monEvenement: undefined, monUtilisateur: undefined, language: lang };
      // console.log("--- getConfig : no token ---");
    } else {
      let sessionUser = sessionStorage.getItem("User");
      if (!sessionUser) {
        try {
          let usr = await this.svcAuth.identify().toPromise();
          if (usr) {
            sessionStorage.setItem("User", JSON.stringify(usr));
            let evt = usr.evenement;
            let lang = (localStorage.getItem("Language") ? localStorage.getItem("Language") : navigator.language.substring(0, 2));
            configLocale = { monEvenement: evt, monUtilisateur: usr, language: lang };
          }
        } catch (error) {
          console.log("ERROR identify", error);
          return undefined;
        }
      } else {
        let usr = JSON.parse(sessionStorage.getItem("User"));
        let evt = usr.evenement;
        let lang = (localStorage.getItem("Language") ? localStorage.getItem("Language") : navigator.language.substring(0, 2));
        configLocale = { monEvenement: evt, monUtilisateur: usr, language: lang };
      }
    }
    // console.log("--- getConfig ---", configLocale);
    return configLocale;
  }
}

export { EvenementsService };
