export * from './authentification.service';
import { AuthentificationService } from './authentification.service';
export * from './commentaires.service';
import { CommentairesService } from './commentaires.service';
export * from './demandes.service';
import { DemandesService } from './demandes.service';
export * from './entreprises.service';
import { EntreprisesService } from './entreprises.service';
export * from './evenements.service';
import { EvenementsService } from './evenements.service';
export * from './test.service';
import { TestService } from './test.service';
export * from './utilisateurs.service';
import { UtilisateursService } from './utilisateurs.service';
export const APIS = [AuthentificationService, CommentairesService, DemandesService, EntreprisesService, EvenementsService, TestService, UtilisateursService];
