/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Evenement } from './evenement';
import { TypeEntreprise } from './typeEntreprise';
import { Utilisateur } from './utilisateur';

export interface Entreprise { 
    id?: number;
    nom?: string;
    nomRecherche?: string;
    type?: TypeEntreprise;
    actif?: boolean;
    evenement?: Evenement;
    utilisateurs?: Array<Utilisateur>;
}