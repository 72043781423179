import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../config.service';
import { BugreportBody, EvenementsService, TestService } from '../server';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-netexpo-contact',
  templateUrl: './netexpo-contact.page.html',
  styleUrls: ['./netexpo-contact.page.scss'],
})
export class NetexpoContactPage implements OnInit {
  appVersion = environment.APP_VERSION;


  constructor(private evtService: EvenementsService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private router: Router,
    private alertController: AlertController,
    private testSvc: TestService,
    private translate: TranslateService) {
  }

  ngOnInit() {

  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

  public presentVersion(ver: string): string {
    let v = ver;
    let tmp = ver.split('.');
    let vNum = (parseInt(tmp[0]) * 100000) + (parseInt(tmp[1]) * 1000) + parseInt(tmp[2]);
    if (vNum < 1000) {
      v += "α"; // &alpha;
    } else if (vNum > 1000 && vNum < 100000) {
      v += "β"; // &beta;";
    }
    return v;
  }

  async bugReport() {
    const alert = await this.alertController.create({
      header: this.translate.instant('bugrep'),
      message: this.translate.instant('thankreport'),
      inputs: [
        {
          name: 'title',
          type: 'text',
          placeholder: this.translate.instant('assoctitle'),
        },
        {
          name: 'message',
          type: 'textarea',
          placeholder: this.translate.instant('pbdescribe'),
        }
      ],
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel',
          handler: () => {
            console.log('BUG REPORT Confirm Cancel');
          }
        }, {
          text: 'Bug',
          handler: async data => {
            if (data.title && data.message) {
              console.log(" REPORT BUG title ", data.title, "message=[", data.message, "]");
              let myBug: BugreportBody = { title: "BUG - " + data.title, message: data.message };
              await this.testSvc.bugReport(myBug).toPromise();
            }
          }
        },
        {
          text: 'Idée',
          handler: async data => {
            if (data.title && data.message) {
              console.log(" REPORT IDEE title ", data.title, "message=[", data.message, "]");
              let myBug: BugreportBody = { title: "IDEE - " + data.title, message: data.message };
              await this.testSvc.bugReport(myBug).toPromise();
            }
          }
        }
      ]
    });
    await alert.present();
    // this.presentToast("avertissement","Pas encore disponible");
  }

}
