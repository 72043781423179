/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Authentification } from './authentification';
import { Commentaire } from './commentaire';
import { Demande } from './demande';
import { DeviceUtilisateur } from './deviceUtilisateur';
import { Disponibilite } from './disponibilite';
import { Entreprise } from './entreprise';
import { Evenement } from './evenement';
import { IntervenantDemande } from './intervenantDemande';
import { NotificationStatuts } from './notificationStatuts';
import { PieceJointeCommentaire } from './pieceJointeCommentaire';
import { PieceJointeDemande } from './pieceJointeDemande';
import { Relance } from './relance';
import { RoleUtilisateur } from './roleUtilisateur';
import { Token } from './token';
import { TypeDemande } from './typeDemande';

export interface Utilisateur { 
    id?: number;
    entrepriseId?: number;
    evenement?: Evenement;
    entreprise?: Entreprise;
    nomContact?: string;
    prenomContact?: string;
    dateCreation?: string;
    dateModif?: string;
    tel?: string;
    email?: string;
    photo?: string;
    role?: RoleUtilisateur;
    actif?: boolean;
    typeDemandes?: Array<TypeDemande>;
    utilisateurTokens?: Array<Token>;
    emetteurRelances?: Array<Relance>;
    emetteurPieceJointeDemandes?: Array<PieceJointeDemande>;
    emetteurPieceJointeCommentaires?: Array<PieceJointeCommentaire>;
    notifications?: Array<NotificationStatuts>;
    deviceUtilisateurs?: Array<DeviceUtilisateur>;
    disponibilites?: Array<Disponibilite>;
    intervenants?: Array<IntervenantDemande>;
    commentaires?: Array<Commentaire>;
    demandes?: Array<Demande>;
    authentifications?: Array<Authentification>;
}