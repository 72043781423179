/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Commentaire } from './commentaire';
import { Espace } from './espace';
import { Evenement } from './evenement';
import { IntervenantDemande } from './intervenantDemande';
import { PieceJointeDemande } from './pieceJointeDemande';
import { Relance } from './relance';
import { StatutDemande } from './statutDemande';
import { TypeDemande } from './typeDemande';
import { Utilisateur } from './utilisateur';

export interface Demande { 
    id?: number;
    evenement?: Evenement;
    espace?: Espace;
    titre?: string;
    description?: string;
    typeDemande?: TypeDemande;
    createur?: Utilisateur;
    dateDemande?: string;
    dateCreation?: string;
    dateLastStatut?: string;
    statut?: StatutDemande;
    commentaires?: Array<Commentaire>;
    intervenants?: Array<IntervenantDemande>;
    pieceJointeDemandes?: Array<PieceJointeDemande>;
    relances?: Array<Relance>;
}