import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log ("intercepting request: " + req.url,"-",environment.API_BASE_PATH);
        if (req.url.indexOf(environment.API_BASE_PATH) !== -1) {
            if (localStorage && localStorage.hasOwnProperty('Token')) {
                // console.log ("token found");
                const userToken = localStorage.getItem('Token');
                const modifiedReq = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${userToken}`),
                });
                return next.handle(modifiedReq);
            }
        }
        // console.log ("token NOT found");
        return next.handle(req);
    }
}