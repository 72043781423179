import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardAccessGuard } from './guard-access.guard';

const routes: Routes = [
  {
    path: 'authentification',
    loadChildren: () => import('./authentification/authentification.module').then(m => m.AuthentificationPageModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: '', canActivate: [GuardAccessGuard], children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'authentification',
        loadChildren: () => import('./authentification/authentification.module').then(m => m.AuthentificationPageModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserPageModule),
      },
      {
        path: 'event',
        loadChildren: () => import('./event/event.module').then(m => m.EventPageModule),
      },
      {
        path: 'request',
        loadChildren: () => import('./request/request.module').then(m => m.RequestPageModule),
      },
      {
        path: 'request/:evtId/:reqId',
        loadChildren: () => import('./request/request.module').then(m => m.RequestPageModule),
      },
      {
        path: 'list',
        loadChildren: () => import('./list/list.module').then(m => m.ListPageModule),
      },
      {
        path: 'select-space',
        loadChildren: () => import('./select-space/select-space.module').then(m => m.SelectSpacePageModule),
      },
      {
        path: 'select-request-type',
        loadChildren: () => import('./select-request-type/select-request-type.module').then(m => m.SelectRequestTypePageModule),
      },
      {
        path: 'select-request-user',
        loadChildren: () => import('./select-request-user/select-request-user.module').then( m => m.SelectRequestUserPageModule)
      },
      {
        path: 'netexpo-contact',
        loadChildren: () => import('./netexpo-contact/netexpo-contact.module').then( m => m.NetexpoContactPageModule)
      },
      {
        path: 'zoom-pj',
        loadChildren: () => import('./zoom-pj/zoom-pj.module').then( m => m.ZoomPjPageModule)
      },
      {
        path: 'floorplan',
        loadChildren: () => import('./floorplan/floorplan.module').then( m => m.FloorplanPageModule)
      },
    ]
  },
  {
    path: 'doc',
    loadChildren: () => import('./doc/doc.module').then( m => m.DocPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
