/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RoleUtilisateur } from './roleUtilisateur';

export interface NouvelUtilisateur { 
    evenementId: number;
    entrepriseId?: number;
    nomEntreprise?: string;
    nomContact: string;
    prenomContact: string;
    telephone: string;
    email?: string;
    photo?: string;
    role?: RoleUtilisateur;
}