import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { EvenementsService } from '../config.service';
import { EventHelperConfig } from '../eventHelperConfig';
import { Buffer } from 'buffer';
@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.page.html',
  styleUrls: ['./floorplan.page.scss'],
})
export class FloorplanPage implements OnInit {
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @Input('plan') plan: string;
  @Input()
  cfg: EventHelperConfig = undefined;
  search: string = '';
  zoom: number = 1;

  zoomStep = 1;
  documents: any;
  floorplan: boolean = false;
  src: ArrayBufferLike;
  fileLoading: boolean = false;
  loading: boolean = false;
  plansList = [];

  constructor(
    private modalCtrl: ModalController,
    private evtSvc: EvenementsService
  ) { }

  ngOnInit() {
    // console.log(' floorplan ngOnInit() plan = ' + this.plan);
    this.lookingForFloorplan();
    if (localStorage.getItem('zoom')) {
      this.zoom = parseFloat(localStorage.getItem('zoom'));
    }
  }
  async lookingForFloorplan() {
    this.loading = true;
    this.documents = await this.evtSvc
      .getDocumentsForEvent(this.cfg.monEvenement.id)
      .toPromise();
    this.documents.forEach((element) => {
      // console.log('element', this.cfg.monEvenement.nomCourt + '_floorplan');
      // workaround : ne pas considéré "planning" comme un "plan"
      if (element.nom.toLowerCase().includes('plan') && !element.nom.toLowerCase().includes('planning')) {
        console.log('found [', element.nom, "]");
        this.floorplan = true;
        this.plansList.push({ "id": element.id, "name": element.nom });
      }
    });
    this.loading = false;
  }

  async changePlan(evt) {
    const id = evt.target.value;
    console.log("changePlan:", evt.target.value);
    if (id) {
      this.loadPlan(evt.target.value);
    }
  }

  async loadPlan(id) {
    this.fileLoading = true;
    var data = await this.evtSvc
      .getDocumentForEvent(this.cfg.monEvenement.id, id)
      .toPromise();
    var reader = new FileReader();
    reader.readAsDataURL(
      new Blob([new Uint8Array(data['data'])])
    );
    reader.onloadend = async () => {
      var base64data = reader.result as string;
      var arr = base64data.split(',');
      var temp = arr[arr.length - 1];
      var arr2 = temp.split('base64');
      var mimeType = arr2[0].substring(4);
      var base64 = arr2[arr2.length - 1];
      const cleanedString = base64.replace(/\s/g, '');
      const byteCharacters = Buffer.from(cleanedString, 'base64').toString('binary');
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      this.src = byteArray;
      this.fileLoading = false;
    };
    this.fileLoading = false;
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  searchFloorplan(stringToSearch: string) {
    this.pdfComponent?.eventBus?.dispatch('find', {
      query: stringToSearch,
      type: 'again',
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
    });
  }

  zoomString(): string {
    return this.zoom.toString();
  }

  zoomIn() {
    this.zoom = this.zoom + this.zoomStep;
    console.log(' zoom = ' + this.zoom, 'IN');
    localStorage.setItem('zoom', this.zoom.toString());
  }
  zoomOut() {
    this.zoom = this.zoom > this.zoomStep ? this.zoom - this.zoomStep : 0;
    console.log(' zoom = ' + this.zoom, 'OUT');
    localStorage.setItem('zoom', this.zoom.toString());
  }

  zoomLoop() {
    this.zoom = this.zoom > 15 ? 1 : this.zoom + this.zoomStep * 3;
    console.log(' zoom = ' + this.zoom, 'LOOP');
    localStorage.setItem('zoom', this.zoom.toString());
  }

  zoomReset() {
    this.zoom = this.zoomStep;
    localStorage.setItem('zoom', this.zoom.toString());
    console.log(' zoom = ' + this.zoom, 'RESET');
  }
}
