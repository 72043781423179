import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, ToastController } from '@ionic/angular';
import { NetexpoContactPage } from 'src/app/netexpo-contact/netexpo-contact.page';
import { TestService } from 'src/app/server';
import { BugreportBody } from 'src/app/server/model/bugreportBody';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  appVersion = environment.APP_VERSION;
  copyrightsYear = new Date().getFullYear();

  constructor(private toaster: ToastController,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    private testSvc: TestService,
    private translate: TranslateService,

  ) { }

  ngOnInit() {
    console.log(" VERSION ", environment.APP_VERSION, " app ", this.appVersion, " value ", this.versionValue(this.appVersion));
    // console.log (" 1.0.0 ",this.versionValue("1.0.0"));
    // console.log (" 0.1.0 BETA ",this.versionValue("0.1.0"));
    // console.log (" 0.0.800 ALPHA ",this.versionValue("0.0.800"));
    // console.log (" 1.2.0 ",this.versionValue("1.2.0"));
    // console.log (" 0.0.100 ",this.versionValue("0.0.100"));
    // console.log (" 0.15.0 ",this.versionValue("0.15.0 "));
    // console.log (" 5.13.250 ",this.versionValue("5.13.250"));

  }

  public versionValue(ver: string): number {
    let tmp = ver.split('.');
    return (parseInt(tmp[0]) * 100000) + (parseInt(tmp[1]) * 1000) + parseInt(tmp[2]);
  }

  public presentVersion(ver: string): string {
    let v = ver;
    let tmp = ver.split('.');
    let vNum = (parseInt(tmp[0]) * 100000) + (parseInt(tmp[1]) * 1000) + parseInt(tmp[2]);
    if (vNum < 1000) {
      v += "α"; // &alpha;
    } else if (vNum > 1000 && vNum < 100000) {
      v += "β"; // &beta;";
    }
    return v;
  }

  async presentToast(type: string, msg: string) {
    const icon = "warning";
    const header = "Avertissement";
    const toast = await this.toaster.create({
      header: header,
      message: msg,
      icon: icon,
      position: 'bottom',
      cssClass:"toastCustom",
      duration: 2000
    });
    toast.present();
  }

  async bugReport() {
    const alert = await this.alertController.create({
      header: this.translate.instant('bug'),
      message: this.translate.instant('amelioration'),
      inputs: [
        {
          name: 'title',
          type: 'text',
          placeholder: this.translate.instant('assoctitle'),
        },
        {
          name: 'message',
          type: 'textarea',
          placeholder: this.translate.instant('nature'),
        }
      ],
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel',
          handler: () => {
            console.log('BUG REPORT Confirm Cancel');
          }
        }, {
          text: 'Bug',
          handler: async data => {
            if (data.title && data.message) {
              console.log(" REPORT BUG title ", data.title, "message=[", data.message, "]");
              let myBug: BugreportBody = { title: "BUG - "+data.title, message: data.message };
              await this.testSvc.bugReport(myBug).toPromise();
            }
          }
        },
        {
          text: 'Idée',
          handler: async data => {
            if (data.title && data.message) {
              console.log(" REPORT IDEE title ", data.title, "message=[", data.message, "]");
              let myBug: BugreportBody = { title: "IDEE - "+data.title, message: data.message };
              await this.testSvc.bugReport(myBug).toPromise();
            }
          }
        }
      ]
    });
    await alert.present();
    // this.presentToast("avertissement","Pas encore disponible");
  }

  async contactNetexpo() {
    console.log("contact Netexpo");
    const modal = await this.modalCtrl.create({
      component: NetexpoContactPage,
      breakpoints: [0, 0.8],
      initialBreakpoint: 0.8,
      handle: false,
    });
    await modal.present();
  }


}
