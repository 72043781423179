/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeviceUtilisateur } from '../model/deviceUtilisateur';
import { Disponibilite } from '../model/disponibilite';
import { IntervenantDemande } from '../model/intervenantDemande';
import { MediaNotification } from '../model/mediaNotification';
import { Notification } from '../model/notification';
import { NouvelleDisponibilite } from '../model/nouvelleDisponibilite';
import { RoleUtilisateur } from '../model/roleUtilisateur';
import { Utilisateur } from '../model/utilisateur';
import { UtilisateurIdDeviceBody } from '../model/utilisateurIdDeviceBody';
import { UtilisateurIdSendMessageBody } from '../model/utilisateurIdSendMessageBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UtilisateursService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Ajouter un device à un utilisateur
     * EX_112
     * @param body Token nouveau device à ajouter
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeviceToUser(body: UtilisateurIdDeviceBody, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<DeviceUtilisateur>;
    public addDeviceToUser(body: UtilisateurIdDeviceBody, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceUtilisateur>>;
    public addDeviceToUser(body: UtilisateurIdDeviceBody, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceUtilisateur>>;
    public addDeviceToUser(body: UtilisateurIdDeviceBody, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDeviceToUser.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling addDeviceToUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeviceUtilisateur>('post',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/device`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Affecter une demande à un utilisateur
     * EX_32
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param demandeId identifiant unique d&#x27;une demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public affectDemandToUser(utilisateurId: number, demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<IntervenantDemande>;
    public affectDemandToUser(utilisateurId: number, demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IntervenantDemande>>;
    public affectDemandToUser(utilisateurId: number, demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IntervenantDemande>>;
    public affectDemandToUser(utilisateurId: number, demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling affectDemandToUser.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling affectDemandToUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IntervenantDemande>('post',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/demande/${encodeURIComponent(String(demandeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Associer un intervenant à un type de demandes.
     * EX_47
     * @param utilisateurId Utilisateur unique de l&#x27;événement
     * @param typeDemandeId Type de demande unique
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public associateUserToATypeDemande(utilisateurId: number, typeDemandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public associateUserToATypeDemande(utilisateurId: number, typeDemandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public associateUserToATypeDemande(utilisateurId: number, typeDemandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public associateUserToATypeDemande(utilisateurId: number, typeDemandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling associateUserToATypeDemande.');
        }

        if (typeDemandeId === null || typeDemandeId === undefined) {
            throw new Error('Required parameter typeDemandeId was null or undefined when calling associateUserToATypeDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/typeDemandes/${encodeURIComponent(String(typeDemandeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les utilisateurs (type intervenant par défaut) disponibles (maintenant - par défaut), pour traiter un type de demande particulier pour un événément
     * EX_31
     * @param evtId Identifiant unique de l&#x27;événement
     * @param typeDemande Identifiant unique du type de demande
     * @param role Role des utilisateurs recherchés
     * @param quand Date de recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailableUsersForATypeDemand(evtId: number, typeDemande: number, role?: RoleUtilisateur, quand?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public getAvailableUsersForATypeDemand(evtId: number, typeDemande: number, role?: RoleUtilisateur, quand?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public getAvailableUsersForATypeDemand(evtId: number, typeDemande: number, role?: RoleUtilisateur, quand?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public getAvailableUsersForATypeDemand(evtId: number, typeDemande: number, role?: RoleUtilisateur, quand?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evtId === null || evtId === undefined) {
            throw new Error('Required parameter evtId was null or undefined when calling getAvailableUsersForATypeDemand.');
        }

        if (typeDemande === null || typeDemande === undefined) {
            throw new Error('Required parameter typeDemande was null or undefined when calling getAvailableUsersForATypeDemand.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }
        if (quand !== undefined && quand !== null) {
            queryParameters = queryParameters.set('quand', <any>quand);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/utilisateurs/evenement/${encodeURIComponent(String(evtId))}/typedemande/${encodeURIComponent(String(typeDemande))}/disponible`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recuperer les notifications par défault d&#x27;un utilisateur
     * EX_1111
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultNotif(utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<MediaNotification>;
    public getDefaultNotif(utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MediaNotification>>;
    public getDefaultNotif(utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MediaNotification>>;
    public getDefaultNotif(utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getDefaultNotif.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MediaNotification>('get',`${this.basePath}/utilisateurs/default/${encodeURIComponent(String(utilisateurId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupérer les nouvelles nofitications pour un utilisateur
     * EX_114
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewNotifications(utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Notification>>;
    public getNewNotifications(utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Notification>>>;
    public getNewNotifications(utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Notification>>>;
    public getNewNotifications(utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getNewNotifications.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Notification>>('get',`${this.basePath}/utilisateur/${encodeURIComponent(String(utilisateurId))}/getNewNotifs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupérer les nouvelles nofitications pour un utilisateur et une demande
     * EX_116
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param demandeId identifiant unique d&#x27;une demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewNotificationsForRequest(utilisateurId: number, demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Notification>>;
    public getNewNotificationsForRequest(utilisateurId: number, demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Notification>>>;
    public getNewNotificationsForRequest(utilisateurId: number, demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Notification>>>;
    public getNewNotificationsForRequest(utilisateurId: number, demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getNewNotificationsForRequest.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getNewNotificationsForRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Notification>>('get',`${this.basePath}/utilisateur/${encodeURIComponent(String(utilisateurId))}/getNewNotifsForRequest/${encodeURIComponent(String(demandeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les types de demandes pour un intervenant.
     * EX_47
     * @param utilisateurId Utilisateur unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTypeDemandesByUser(utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public getTypeDemandesByUser(utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public getTypeDemandesByUser(utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public getTypeDemandesByUser(utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getTypeDemandesByUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Utilisateur>('get',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/typeDemandes/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Afficher les disponibilités d&#x27;un intervenant par jour pour un évènement
     * EX_44
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param jour jour dont on souhaite connaitre la disponibilite de l&#x27;intervenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAvailibility(utilisateurId: number, jour: string, observe?: 'body', reportProgress?: boolean): Observable<Disponibilite>;
    public getUserAvailibility(utilisateurId: number, jour: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Disponibilite>>;
    public getUserAvailibility(utilisateurId: number, jour: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Disponibilite>>;
    public getUserAvailibility(utilisateurId: number, jour: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getUserAvailibility.');
        }

        if (jour === null || jour === undefined) {
            throw new Error('Required parameter jour was null or undefined when calling getUserAvailibility.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Disponibilite>('get',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/disponibilite/${encodeURIComponent(String(jour))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les utilisateurs d&#x27;une entreprise
     * EX_37
     * @param entrepriseId Identifiant unique de l&#x27;entreprise
     * @param role Role des utilisateurs recherchés
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersByCompany(entrepriseId: number, role?: RoleUtilisateur, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public getUsersByCompany(entrepriseId: number, role?: RoleUtilisateur, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public getUsersByCompany(entrepriseId: number, role?: RoleUtilisateur, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public getUsersByCompany(entrepriseId: number, role?: RoleUtilisateur, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entrepriseId === null || entrepriseId === undefined) {
            throw new Error('Required parameter entrepriseId was null or undefined when calling getUsersByCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/utilisateurs/entreprises/${encodeURIComponent(String(entrepriseId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les utilisateurs correspondant à la recherche
     * EX_18
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param role Role de l&#x27;utilisateur
     * @param recherche Mot recherché
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsersByEventId(evenementId: number, role: RoleUtilisateur, recherche: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public searchUsersByEventId(evenementId: number, role: RoleUtilisateur, recherche: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public searchUsersByEventId(evenementId: number, role: RoleUtilisateur, recherche: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public searchUsersByEventId(evenementId: number, role: RoleUtilisateur, recherche: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchUsersByEventId.');
        }

        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling searchUsersByEventId.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling searchUsersByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/utilisateurs/evenement/${encodeURIComponent(String(evenementId))}/role/${encodeURIComponent(String(role))}/recherche/${encodeURIComponent(String(recherche))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les utilisateurs selon recherche plein texte
     * EX_80
     * @param evenementId identifiant unique d&#x27;un evenement
     * @param searchFullText Mot recherché
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsersFullText(evenementId: number, searchFullText: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public searchUsersFullText(evenementId: number, searchFullText: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public searchUsersFullText(evenementId: number, searchFullText: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public searchUsersFullText(evenementId: number, searchFullText: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchUsersFullText.');
        }

        if (searchFullText === null || searchFullText === undefined) {
            throw new Error('Required parameter searchFullText was null or undefined when calling searchUsersFullText.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/utilisateurs/evenement/${encodeURIComponent(String(evenementId))}/recherche/${encodeURIComponent(String(searchFullText))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les utilisateurs pouvant être intervenant
     * EX_113
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsersIntervenants(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public searchUsersIntervenants(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public searchUsersIntervenants(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public searchUsersIntervenants(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchUsersIntervenants.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/utilisateurs/evenement/${encodeURIComponent(String(evenementId))}/intervenants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Envoyer un SMS ou EMAIL à un utilisateur.
     * EX_118
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMessageToUser(evenementId: number, utilisateurId: number, body?: UtilisateurIdSendMessageBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendMessageToUser(evenementId: number, utilisateurId: number, body?: UtilisateurIdSendMessageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendMessageToUser(evenementId: number, utilisateurId: number, body?: UtilisateurIdSendMessageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendMessageToUser(evenementId: number, utilisateurId: number, body?: UtilisateurIdSendMessageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling sendMessageToUser.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling sendMessageToUser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/utilisateurs/${encodeURIComponent(String(evenementId))}/utilisateur/${encodeURIComponent(String(utilisateurId))}/sendMessage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mise à jour des notifications par défault pour un utilisateur
     * EX_1112
     * @param body Type de notification à ajouter
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDefaultNotif(body: any, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDefaultNotif(body: any, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDefaultNotif(body: any, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDefaultNotif(body: any, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDefaultNotif.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling updateDefaultNotif.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/utilisateurs/updateDefault/${encodeURIComponent(String(utilisateurId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marquer comme vu une notification pour un utilisateur
     * EX_115
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param notifId identifiant unique d&#x27;une notification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSeenNotification(utilisateurId: number, notifId: number, observe?: 'body', reportProgress?: boolean): Observable<Notification>;
    public updateSeenNotification(utilisateurId: number, notifId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Notification>>;
    public updateSeenNotification(utilisateurId: number, notifId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Notification>>;
    public updateSeenNotification(utilisateurId: number, notifId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling updateSeenNotification.');
        }

        if (notifId === null || notifId === undefined) {
            throw new Error('Required parameter notifId was null or undefined when calling updateSeenNotification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Notification>('put',`${this.basePath}/utilisateur/${encodeURIComponent(String(utilisateurId))}/notifSeen/${encodeURIComponent(String(notifId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier ou créer la disponibilité d&#x27;un utilisateur pour un jour donné
     * EX_45
     * @param body Disponibilité à créer ou à mettre à jour
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param jour jour dont on souhaite connaitre la disponibilite de l&#x27;intervenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserAvailibiltyForADay(body: NouvelleDisponibilite, utilisateurId: number, jour: string, observe?: 'body', reportProgress?: boolean): Observable<Disponibilite>;
    public updateUserAvailibiltyForADay(body: NouvelleDisponibilite, utilisateurId: number, jour: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Disponibilite>>;
    public updateUserAvailibiltyForADay(body: NouvelleDisponibilite, utilisateurId: number, jour: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Disponibilite>>;
    public updateUserAvailibiltyForADay(body: NouvelleDisponibilite, utilisateurId: number, jour: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserAvailibiltyForADay.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling updateUserAvailibiltyForADay.');
        }

        if (jour === null || jour === undefined) {
            throw new Error('Required parameter jour was null or undefined when calling updateUserAvailibiltyForADay.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Disponibilite>('post',`${this.basePath}/utilisateurs/${encodeURIComponent(String(utilisateurId))}/disponibilite/${encodeURIComponent(String(jour))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
