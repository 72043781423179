import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GuardAccessGuard implements CanActivate {

  constructor(private router: Router) { }

  async canActivate(): Promise<boolean> {
    // console.log ('GUARD canActivate');
    const token = await localStorage.getItem('Token');
    let result = false;
    if (!token || token == "false") {
      this.router.navigateByUrl('/authentification');
    } else {
      result = true;
    }
    // console.log ('GUARD canActivate = ',result);
    return result;
  }

}
