/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Commentaire } from '../model/commentaire';
import { Demande } from '../model/demande';
import { IntervenantDemande } from '../model/intervenantDemande';
import { Notification } from '../model/notification';
import { NouveauCommentaire } from '../model/nouveauCommentaire';
import { NouvellePieceJointe } from '../model/nouvellePieceJointe';
import { ParIntervenantIdBody } from '../model/parIntervenantIdBody';
import { ParamDemande } from '../model/paramDemande';
import { PieceJointe } from '../model/pieceJointe';
import { StatutDemande } from '../model/statutDemande';
import { TypeNotification } from '../model/typeNotification';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DemandesService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Ajouter un commentaire à une demande
     * EX_63
     * @param demandeId Identifiant unique de la demande
     * @param body Commentaire à ajouter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCommentDemande(demandeId: number, body?: NouveauCommentaire, observe?: 'body', reportProgress?: boolean): Observable<Commentaire>;
    public addCommentDemande(demandeId: number, body?: NouveauCommentaire, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Commentaire>>;
    public addCommentDemande(demandeId: number, body?: NouveauCommentaire, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Commentaire>>;
    public addCommentDemande(demandeId: number, body?: NouveauCommentaire, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling addCommentDemande.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Commentaire>('post',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/comment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer une nouvelle demande
     * EX_23
     * @param body Nouvelle demande à créer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDemande(body: ParamDemande, observe?: 'body', reportProgress?: boolean): Observable<Demande>;
    public addDemande(body: ParamDemande, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Demande>>;
    public addDemande(body: ParamDemande, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Demande>>;
    public addDemande(body: ParamDemande, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Demande>('post',`${this.basePath}/demandes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter une pièce jointe à un commentaire
     * EX_65
     * @param body Pièce jointe à ajouter
     * @param demandeId Identifiant unique de la demande
     * @param commentaireId Identifiant unique du commentaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPieceJointeToComment(body: NouvellePieceJointe, demandeId: number, commentaireId: number, observe?: 'body', reportProgress?: boolean): Observable<PieceJointe>;
    public addPieceJointeToComment(body: NouvellePieceJointe, demandeId: number, commentaireId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PieceJointe>>;
    public addPieceJointeToComment(body: NouvellePieceJointe, demandeId: number, commentaireId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PieceJointe>>;
    public addPieceJointeToComment(body: NouvellePieceJointe, demandeId: number, commentaireId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addPieceJointeToComment.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling addPieceJointeToComment.');
        }

        if (commentaireId === null || commentaireId === undefined) {
            throw new Error('Required parameter commentaireId was null or undefined when calling addPieceJointeToComment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PieceJointe>('post',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/commentaire/${encodeURIComponent(String(commentaireId))}/pieceJointe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter une pièce jointe à une demande
     * EX_64
     * @param body Pièce jointe à ajouter
     * @param demandeId Identifiant unique de la demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPieceJointeToDemande(body: NouvellePieceJointe, demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<PieceJointe>;
    public addPieceJointeToDemande(body: NouvellePieceJointe, demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PieceJointe>>;
    public addPieceJointeToDemande(body: NouvellePieceJointe, demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PieceJointe>>;
    public addPieceJointeToDemande(body: NouvellePieceJointe, demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addPieceJointeToDemande.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling addPieceJointeToDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PieceJointe>('post',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/pieceJointe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nombre de messages pour une demande
     * EX_124
     * @param demandeId identifiant unique d&#x27;une demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countMsgByDemandeId(demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countMsgByDemandeId(demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countMsgByDemandeId(demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countMsgByDemandeId(demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling countMsgByDemandeId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/chatCount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un commentaire à une demande
     * EX_107
     * @param demandeId identifiant unique d&#x27;une demande
     * @param commentaireId identifiant unique d&#x27;un commentaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCommentToDemand(demandeId: number, commentaireId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCommentToDemand(demandeId: number, commentaireId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCommentToDemand(demandeId: number, commentaireId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCommentToDemand(demandeId: number, commentaireId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling deleteCommentToDemand.');
        }

        if (commentaireId === null || commentaireId === undefined) {
            throw new Error('Required parameter commentaireId was null or undefined when calling deleteCommentToDemand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/commentaires/${encodeURIComponent(String(commentaireId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * récupérer le contenu d&#x27;une pièce jointe
     * EX_123
     * @param demandeId identifiant unique d&#x27;une demande
     * @param pieceJointeId identifiant unique d&#x27;une pièce jointe
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatAttachmentById(demandeId: number, pieceJointeId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getChatAttachmentById(demandeId: number, pieceJointeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getChatAttachmentById(demandeId: number, pieceJointeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getChatAttachmentById(demandeId: number, pieceJointeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getChatAttachmentById.');
        }

        if (pieceJointeId === null || pieceJointeId === undefined) {
            throw new Error('Required parameter pieceJointeId was null or undefined when calling getChatAttachmentById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/chat/attachment/${encodeURIComponent(String(pieceJointeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les commentaires dans le chat pour une demande
     * EX_122
     * @param demandeId identifiant unique d&#x27;une demande
     * @param page N° page
     * @param count Nombre de résultats par page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatByDemandeId(demandeId: number, page?: number, count?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Commentaire>>;
    public getChatByDemandeId(demandeId: number, page?: number, count?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Commentaire>>>;
    public getChatByDemandeId(demandeId: number, page?: number, count?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Commentaire>>>;
    public getChatByDemandeId(demandeId: number, page?: number, count?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getChatByDemandeId.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Commentaire>>('get',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/chat/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les commentaires pour une demande
     * EX_66
     * @param demandeId Identifiant unique de la demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommentsForDemande(demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Commentaire>>;
    public getCommentsForDemande(demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Commentaire>>>;
    public getCommentsForDemande(demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Commentaire>>>;
    public getCommentsForDemande(demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getCommentsForDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Commentaire>>('get',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/commentaires/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les intervenants pour une demande
     * EX_50
     * @param demandeId identifiant unique d&#x27;une demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntervenantsDemande(demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IntervenantDemande>>;
    public getIntervenantsDemande(demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IntervenantDemande>>>;
    public getIntervenantsDemande(demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IntervenantDemande>>>;
    public getIntervenantsDemande(demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getIntervenantsDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<IntervenantDemande>>('get',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/intervenants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notifier un utilisateur d&#x27;un changement ou d&#x27;un ajout à une demande par un autre utilisateur
     * EX_29
     * @param demandeId identifiant unique d&#x27;une demande
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param typeNotification nature de la notification
     * @param intervenantId identifiant unique d&#x27;un utilisateur (intervenant)
     * @param body Complément de notification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notifyDemandeUpdate(demandeId: number, utilisateurId: number, typeNotification: TypeNotification, intervenantId: number, body?: ParIntervenantIdBody, observe?: 'body', reportProgress?: boolean): Observable<Array<Notification>>;
    public notifyDemandeUpdate(demandeId: number, utilisateurId: number, typeNotification: TypeNotification, intervenantId: number, body?: ParIntervenantIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Notification>>>;
    public notifyDemandeUpdate(demandeId: number, utilisateurId: number, typeNotification: TypeNotification, intervenantId: number, body?: ParIntervenantIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Notification>>>;
    public notifyDemandeUpdate(demandeId: number, utilisateurId: number, typeNotification: TypeNotification, intervenantId: number, body?: ParIntervenantIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling notifyDemandeUpdate.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling notifyDemandeUpdate.');
        }

        if (typeNotification === null || typeNotification === undefined) {
            throw new Error('Required parameter typeNotification was null or undefined when calling notifyDemandeUpdate.');
        }

        if (intervenantId === null || intervenantId === undefined) {
            throw new Error('Required parameter intervenantId was null or undefined when calling notifyDemandeUpdate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Notification>>('post',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/notifier/${encodeURIComponent(String(utilisateurId))}/type/${encodeURIComponent(String(typeNotification))}/par/${encodeURIComponent(String(intervenantId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transmettre une demande à un autre intervenant de la même entreprise
     * EX_48
     * @param demandeId identifiant unique d&#x27;une demande
     * @param intervenantId identifiant unique d&#x27;un utilisateur (intervenant)
     * @param utilisateurId identifiant unique d&#x27;un utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transferDemandToIntervByUser(demandeId: number, intervenantId: number, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<IntervenantDemande>;
    public transferDemandToIntervByUser(demandeId: number, intervenantId: number, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IntervenantDemande>>;
    public transferDemandToIntervByUser(demandeId: number, intervenantId: number, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IntervenantDemande>>;
    public transferDemandToIntervByUser(demandeId: number, intervenantId: number, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling transferDemandToIntervByUser.');
        }

        if (intervenantId === null || intervenantId === undefined) {
            throw new Error('Required parameter intervenantId was null or undefined when calling transferDemandToIntervByUser.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling transferDemandToIntervByUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IntervenantDemande>('put',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/transfertTo/${encodeURIComponent(String(intervenantId))}/by/${encodeURIComponent(String(utilisateurId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mettre à jour une demande
     * EX_58
     * @param body Demande à modifier
     * @param demandeId Identifiant unique de la demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDemande(body: ParamDemande, demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Demande>;
    public updateDemande(body: ParamDemande, demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Demande>>;
    public updateDemande(body: ParamDemande, demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Demande>>;
    public updateDemande(body: ParamDemande, demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDemande.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling updateDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Demande>('put',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liste des demandes nouvelles, pour un événement, par rapport à une liste de demande (retourne uniquement les différences)
     * EX_25
     * @param body liste de demande non à jour
     * @param evenementId identifiant unique d&#x27;un evenement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDemandes(body: any, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDemandes(body: any, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDemandes(body: any, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDemandes(body: any, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDemandes.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateDemandes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/NewDemandes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mettre à jour le statut d&#x27;une demande
     * EX_58b
     * @param demandeId identifiant unique d&#x27;une demande
     * @param statut identifiant unique d&#x27;une entreprise
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatutDemande(demandeId: number, statut: StatutDemande, observe?: 'body', reportProgress?: boolean): Observable<Demande>;
    public updateStatutDemande(demandeId: number, statut: StatutDemande, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Demande>>;
    public updateStatutDemande(demandeId: number, statut: StatutDemande, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Demande>>;
    public updateStatutDemande(demandeId: number, statut: StatutDemande, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling updateStatutDemande.');
        }

        if (statut === null || statut === undefined) {
            throw new Error('Required parameter statut was null or undefined when calling updateStatutDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Demande>('put',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/statut/${encodeURIComponent(String(statut))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
