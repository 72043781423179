import { Injectable } from '@angular/core';
import { getMessaging, getToken } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { UtilisateursService } from './server';

import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // private app: FirebaseApp;

  constructor(private userSvc: UtilisateursService) {
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Initialize Firebase
    // if (!this.app) {
    //   this.app = initializeApp(environment.firebase);
    // }
  }

  isIos() {
    const browserInfo = navigator.userAgent.toLowerCase();
    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      return true;
    }
    return false;
  }

  isSupported() {
    return ('Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window);
  }

  browserVersion() {
    var ua = navigator.userAgent;
    var tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }


  public requestPermissionCurrentUser(userId: number) {
    if (this.isIos() || !this.isSupported()) {
      let OSName = "Unknown OS";
      if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
      if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
      if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
      if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
      if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
      let errorMsg = "FCM PushNotif - NOT SUPPORTED "+(this.isSupported() ?"NO":"YES") + " [" + OSName + "-" + navigator.platform + "] " + this.browserVersion() +
            " Notif="+('Notification' in window)+" ServiceWorker="+ ('serviceWorker' in navigator) + " PushManager="+('PushManager' in window);
      Sentry.captureMessage(errorMsg);
      console.log(errorMsg);
    } else {
      console.log('FCM PushNotif - Requesting permission...');
      Notification.requestPermission().then((permission) => {
        // Sentry.captureMessage("FCM permission=" + permission);
        if (permission === 'granted') {
          console.log('FCM PushNotif - Notification permission granted.');
          // Get registration token. Initially this makes a network call, once retrieved
          // subsequent calls to getToken will return from cache.
          if ('serviceWorker' in navigator) {
            const messaging = getMessaging();
            // vapidKey = certificat web push (firebase console cloud messaging) https://console.firebase.google.com/project/eventhelper-dev/settings/cloudmessaging
            // getToken(messaging, { vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' }).then((currentToken) => {
            getToken(messaging, { vapidKey: environment.FCM_KEY }).then(async (currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                let check = await this.userSvc.addDeviceToUser({ token: currentToken }, userId).toPromise();
                console.log("FCM PushNotif - AJOUT USER [" + userId + "] device TOKEN : " + currentToken + " result =", check);
                // ...
              } else {
                // Show permission request UI
                console.log('FCM PushNotif - No registration token available. Request permission to generate one.');
                // ...
              }
            }).catch((err) => {
              console.log('FCM PushNotif - An error occurred while retrieving token. ', err);
              // ...
            });
          } else {
            let OSName = "Unknown OS";
            const userAgent = (navigator as Navigator).userAgent;
            const n = userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
            const v = userAgent.match(/version\/([\.\d]+)/i);
            if (userAgent.indexOf("Win") != -1) OSName = "Windows";
            if (userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
            if (userAgent.indexOf("Linux") != -1) OSName = "Linux";
            if (userAgent.indexOf("Android") != -1) OSName = "Android";
            if (userAgent.indexOf("like Mac") != -1) OSName = "iOS";
            let errorMsg = 'FCM PushNotif - ServiceWorker NOT AVAILABLE [' + OSName + '] ' + n + ' v' + v + "os=" +  (navigator as Navigator).platform;
            Sentry.captureMessage(errorMsg);
            console.log(errorMsg);
          }
        } else {
          console.log('FCM PushNotif - Permission [' + permission + ']');
        }
      });
    }
  }
}
