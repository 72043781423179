import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    // private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('fr');
    //TODO #1 A modifier pour gérer un cookie local pour la langue de l'utilisateur
  }

}
