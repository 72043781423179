import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { EventHelperConfig } from 'src/app/eventHelperConfig';
import { Commentaire, Demande, DemandesService, NouveauCommentaire } from 'src/app/server';

import { blobToURL, fromBlob } from 'image-resize-compress';
import { ZoomPjPage } from 'src/app/zoom-pj/zoom-pj.page';
import { ModalController } from '@ionic/angular';
import { CommonNetexpoModule } from '../common-netexpo.module';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// const INTERVAL_TIME = 120000 // 2 minutes;
const INTERVAL_TIME = 30000 // 30'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @ViewChild('ionInputElRef', { read: ElementRef }) ionInputElRef: ElementRef;
  // @Input()
  // hauteurPx:number ;
  @Input()
  mode: boolean = false;
  @Input()
  maConfig: EventHelperConfig;
  @Input()
  isRequest: boolean=false;
  // @Input()
  // discussion:Commentaire[] ;
  // @Input()
  // sendingMsg:boolean;
  // @Input()
  // attachment:Array<File>;
  @Input()
  demande: Demande;
  // @Input()
  // currentRequest:any;

  // attachment:Array<File>;
  attachment = [];
  discussion: Commentaire[];
  countMsgForDemande: number;
  historyFullyLoaded = false;
  sendingMsg: boolean;
  sendingError: string;
  loadingDiscussion: boolean;

  refreshTimer: any;

  // test lazy loading image
  // myImage: Observable<any> = from(fetch('https://source.unsplash.com/random')).pipe(map(data => data.url));

  newComment = "";
  constructor(public common: CommonNetexpoModule,
    private modalCtrl: ModalController,
    private demandeSvc: DemandesService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    // si nous sommes dans la page request, on fait le signal au css pour adapter le chat
if(this.isRequest){
    let container=Array.from(document.getElementsByClassName('page-container') as HTMLCollectionOf<HTMLElement>)[0]
    container.classList.toggle('helpcontainer')
    container.classList.toggle('requestcontainer')
}
    // this.discussion = await this.demandeSvc.getCommentsForDemande(this.demande.id).toPromise();
    if (this.demande) {
      this.loadingDiscussion = true;
      this.historyFullyLoaded = false;
      // this.discussion = await this.demandeSvc.getChatByDemandeId(this.demande.id).toPromise();
      // this.discussion.reverse();
      this.discussion = [];
      this.loadDiscussionPage(1);
      this.startTimer();
      this.loadingDiscussion = false;
      // window.addEventListener('resize', this.resizeContent);
      setTimeout(() => {
        // this.loadingDiscussion = false;
        // this.resizeContent();
        // scroll to bottom (au chargement)
        this.scrollToBottom();
      }, 1000);
    }

  }

  resizeContent() {
    console.log('resizeContent --')
    let toolbarH = document.getElementsByTagName('ion-toolbar')[0]?.offsetHeight;
    let segmentH = document.getElementById('headerSelectSegment')?.offsetHeight;
    let requesH = document.getElementById('blockRequestDetail')?.offsetHeight;
    let inputH = Array.from(document.getElementsByClassName('input-container') as HTMLCollectionOf<HTMLElement>)[0].offsetHeight

    const myView = document.getElementById('scrollDiv');
    console.log('resizeContent : window ', window.outerHeight, ' toolbar', toolbarH, 'segment', segmentH, 'request', requesH, 'inpout', inputH, 'before ', myView?.style?.height);

    if (myView) {
      const newSize = (window.innerHeight - toolbarH - segmentH - requesH - inputH - 1 - 20);
      myView.style.height = newSize.toString() + 'px';
      console.log('resizeContent : to  ', newSize);
    }
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }


  startTimer() {
    if (this.refreshTimer != undefined) {
      clearInterval(this.refreshTimer);
    }
    console.log(" CHAT TIMER every ", INTERVAL_TIME / 1000 / 60, "' starts at ", new Date().toLocaleTimeString());
    this.refreshTimer = setInterval(() => { this.updateChat(true) }, INTERVAL_TIME);
  }

  async updateChat(fromTimer: boolean = false) {
    console.log((fromTimer ? "[TIMER]" : "[NOT TIMER]")," CHAT Update @", new Date().toLocaleTimeString());
    const newCount = await this.demandeSvc.countMsgByDemandeId(this.demande.id).toPromise();
    // console.log(" newCount ", this.countMsgForDemande, " -> ", newCount);
    if (newCount > this.countMsgForDemande) {
      const msgToLoad = newCount - this.countMsgForDemande;
      // on va recupérer les messages manquants (page 1 - les derniers et les N 'msgToLoad'  manquants)
      let newMsg = await this.demandeSvc.getChatByDemandeId(this.demande.id, 1, msgToLoad).toPromise();
      newMsg.reverse();
      newMsg.forEach(e => { this.discussion.find(elt => elt.id == e.id) ? null : this.discussion.push(e); });
      this.countMsgForDemande = newCount;
      if (fromTimer) {
        this.scrollToBottom();
      }
      // il nous manque des messages (on a reçu des messages depuis l'update précédent)
      // il faut charges les N derniers messages et fusioner avec la liste discussion actuelle
      //  this.loadMoreDiscussion(null);
    }
  }

  refreshChat() {
    this.updateChat();
    this.scrollToBottom();
  }

  async loadDiscussionPage(page: number) {
    // ajout verif nombre de messages pour assurer une synchronisation si nouveaux msg entre temps
    let newCount = this.countMsgForDemande = await this.demandeSvc.countMsgByDemandeId(this.demande.id).toPromise();
    console.log(" START ", this.countMsgForDemande, " -> ", newCount);
    if (this.countMsgForDemande == undefined) {
      this.countMsgForDemande = newCount;
    } else if (newCount > this.countMsgForDemande) {
      // il nous manque des messages (on a reçu des messages depuis chargement initial)
      console.log(" COUNT ", this.countMsgForDemande, " -> ", newCount);
      await this.updateChat();
      // il faut actualiser la liste des messages récents avant de charger des anciens
    }
    let newMsg = await this.demandeSvc.getChatByDemandeId(this.demande.id, page).toPromise();
    if (newMsg.length < 10) {
      this.historyFullyLoaded = true;
    }
    newMsg.reverse();
    this.discussion = newMsg.concat(this.discussion);
    console.log(" discussion ", this.discussion.length, "->", this.discussion);
    if (page === 1) {
      this.scrollToBottom();
    }

  }

  async loadMoreDiscussion(event) {
    console.log('loadMore ', event);
    if (this.discussion.length >= this.countMsgForDemande) {
      this.historyFullyLoaded = true;
    }
    if (!this.historyFullyLoaded) {
      console.log("loadMoreDiscussion (UPDATECHAT)");
      await this.updateChat();
      console.log('loadMoreDiscussion', this.discussion.length);
      const currentHeight = document?.getElementById('scrollDiv')?.scrollHeight;
      console.log('current', currentHeight);
      await this.loadDiscussionPage((this.discussion.length / 10) + 1);
      this.scrollToPosition(currentHeight);
    } else {
      console.log('loadMoreDiscussion NOT needed');
    }
    if (event) {
      event.target.complete();
    }
    // setTimeout(() => {
    //   console.log('Pause');
    // }, 1000);
  }

  getBaseUrl() {
    return environment.API_BASE_PATH;
  }

  getAttachmentUrl(id: number) {
    return this.getBaseUrl() + '/demandes/' + this.demande.id + '/chat/attachment/' + id
  }


  loadAttachment(id: number): string {
    console.log(" Attachment load ", id);
    // inifinite loop !!!!!!!!
    // let base64 = undefined;
    // const attach = this.demandeSvc.getChatAttachmentById(this.demande.id, id).toPromise().then(data => {
    //   console.log(" Attachment loaded ", id, " => ", data.size, " bytes");
    //   base64 = 'data:image/jpg;base64,' + data.toString();
    //    return data;
    // });
    // console.log (" return base64");
    // return base64;

    // mutliple times !!!
    // return ("https://source.unsplash.com/random");

    // demandes/:demandeId/chat/attachment/:attachId
    // const url = "http://localhost:5001/eventhelper-dev/europe-west1/app/demandes/328/chat/attachment/" + id.toString();
    // console.log(" load URL", url);
    return ("");
  }

  // testAttachment(id: number): string {
  //   console.log(" Attachment test ", id);
  //   const attach = 'https://source.unsplash.com/random';
  //   // const attach = from(fetch('https://source.unsplash.com/random')).pipe(map(data => data.url));
  //   // // console.log(" Attachment loaded ", id, " => ", attach.length, " bytes");
  //   return attach;
  // }


  attachDocument(event: Event) {
    console.log(" attachDocument ", event);
    if (event && event.target) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        for (let i = 0; i < target.files.length; i++) {
          const file = target.files[i];
          this.attachment.push(file);
        }
      }
    }
  }
  clearAttachments() {
    console.log(" clearAttachments ");
    this.attachment = [];
  }
  chatColor(userid): string {
    // console.log(" chatColor user=", userid, "me= ",this.maConfig.monUtilisateur.id); 
    if (userid == this.maConfig.monUtilisateur.id) {
      return "";
    } else {
      let colorValue = ((userid * 50) % 360);
      // if (colorValue > 160 && colorValue < 230) {
      //   colorValue = colorValue - 100;
      // }
      // console.log ("color HSL [", colorValue, "] not My msg");
      return "background-color: hsl(" + colorValue + ", 67%, 87%)";
    }
  }

  async sendComment() {
    console.log("sendComment emetteur=", this?.maConfig?.monUtilisateur?.id, " currentRequest=", this?.demande?.id, " request=", this?.demande?.id);
    this.sendingError = undefined;
    if (this.newComment.length || this.attachment.length) {
      this.sendingMsg = true;
      // on actualise les messages avant d'ajouter le notre
      await this.updateChat();
      try {
        let comment: NouveauCommentaire = { commentaire: this.newComment, emetteurId: this.maConfig.monUtilisateur.id };
        let newComment = await this.demandeSvc.addCommentDemande(this.demande.id, comment).toPromise();
        if (this.attachment.length > 0) {
          for (const element of this.attachment) {
            const blob = await fromBlob(element, 70, 1000, 'auto', 'webp');
            const url = await blobToURL(blob);
            console.log("COMPRESS-RESIZE from (", (element.size / 1024).toFixed(0) + " Ko.", ") => ", (url.length / 1024).toFixed(0) + " Ko. gain= ", ((1 - (url.length / element.size)) * 100).toFixed(0) + "%");
            const pj = await this.demandeSvc.addPieceJointeToComment({ contenu: url, emetteurId: this.maConfig.monUtilisateur.id }, this.demande.id, newComment.id).toPromise();
            console.log(" ATTACH ", pj);
            if (!newComment.pieceJointeCommentaires) {
              newComment.pieceJointeCommentaires = [];
            }
            newComment.pieceJointeCommentaires.push(pj);
          }
        }
        this.discussion.push(newComment);
        this.countMsgForDemande++;
        console.log(" COMMENT = ", newComment);
        this.newComment = "";
        this.attachment = [];
        this.clearAttachments();
        this.scrollToBottom();
        setTimeout(() => {
          this.focusInputChat();
        }, 200);
      } catch (error) {
        console.log("sendComment error ", error);
        this.sendingError = "Erreur!";
      } finally {
        this.sendingMsg = false;
      }
    }
  }

  focusInputChat() {
    const el = this.ionInputElRef?.nativeElement?.querySelector('input') as HTMLInputElement;
    if (el) {
      el.focus();
    } else {
      setTimeout(() => {
        const el = this.ionInputElRef?.nativeElement?.querySelector('input') as HTMLInputElement;
        if (el) {
          el.focus();
        }
      }, 200);
    }
  }

  async afficherPieceJointe(indexDiscussion, indexPj) {
    // console.log("indexDiscussion",indexDiscussion);
    // console.log("indexPj",indexPj);
    console.log("index", indexPj);
    let img = indexPj;
    let pjs = this.discussion[indexDiscussion].pieceJointeCommentaires;
    const modal = await this.modalCtrl.create({
      component: ZoomPjPage,
      cssClass: 'zoom_img',
      componentProps: {
        img,
        pjs,
        demande: this.demande.id
      }
    });
    modal.present();
  }

  scrollToPosition(previousHeight?: number) {
    let chat = document.getElementById('scrollDiv');
    if (chat) {
      let position = chat.scrollHeight;
      if (previousHeight) {
        console.log("previousHeight");

        position = chat.scrollHeight - previousHeight - 84;
        chat.scrollTo(0, position);
      } else {
        setTimeout(() => {
          chat = document.getElementById('scrollDiv');
          if (chat) {
            chat.scrollTo(0, chat.scrollHeight);
          }
        }, 500);
      }
    }
    // si pas de chat va chercher chat
    else {
      setTimeout(() => {
        chat = document.getElementById('scrollDiv');
        if (chat) {
          chat.scrollTo(0, chat.scrollHeight);
          console.log("scrollToBottom");
        }
        this.focusInputChat();
      }, 500);
    }
  }

  scrollToBottom() {
    this.scrollToPosition();
  }
}
