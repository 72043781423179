import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonList, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CommonNetexpoModule } from '../common-netexpo/common-netexpo.module';
import { ConfigService } from '../config.service';
import { EventHelperConfig } from '../eventHelperConfig';
import { Entreprise, TypeEntreprise, EvenementsService, NouvelUtilisateur, RoleUtilisateur, Utilisateur, UtilisateursService, AuthentificationService } from '../server';

@Component({
  selector: 'app-select-request-user',
  templateUrl: './select-request-user.page.html',
  styleUrls: ['./select-request-user.page.scss'],
})
export class SelectRequestUserPage implements OnInit {
  @ViewChild('liste') liste: IonList
  @Input() myElementSync: Subject<Utilisateur>;
  @Input() allUsers: boolean;
  // paramètre entreprise connue ID ? ou nom ?
  @Input() selectedEnterprise: Entreprise;
  @Input() title: string;
  @Input() infoGet: boolean;

  readonly valeursRole = RoleUtilisateur;
  readonly valeursTypeEntreprise = TypeEntreprise;

  loading = true;
  waiting = false;
  userError = "";

  userCreate = false;
  userUpdate = false;
  maConfig: EventHelperConfig;
  users: Utilisateur[];
  currentUser: Utilisateur;
  searchString: string = "";
  role: RoleUtilisateur;
  countSearch: number = 0;

  searchingCompanies: boolean = false;
  companiesList: Entreprise[] = undefined;
  currentEntreprise: Entreprise;
  inputEnterprise: string = undefined;
  showDetails: boolean;
  userDetails: any;
  qrdata: string;

  sortDirection: boolean = false;
  lastSortOrder: string = '';

  constructor(private evtService: EvenementsService,
    private authService: AuthentificationService,
    private userService: UtilisateursService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    public common: CommonNetexpoModule,
    private alertController: AlertController,
    private router: Router,
    private translate: TranslateService) {
  }

  createUser() {
    this.userError = "";
    this.userCreate = true;

    this.currentEntreprise = { nom: "", type: TypeEntreprise.Exposant };
    this.inputEnterprise = "";
    this.currentUser = {
      evenement: this.maConfig.monEvenement,
      nomContact: "",
      prenomContact: "",
      tel: "",
      email: "",
      role: this.allUsers ? RoleUtilisateur.Demandeur : RoleUtilisateur.Intervenant,
      entreprise: this.currentEntreprise,
    };
  }

  onChangeRole(inputEvent: any) {
    // console.log("onChangeRole", this.currentUser, "event ", inputEvent.detail.value);
  }

  selectCompany(company: Entreprise) {
    this.companiesList = undefined;
    this.currentEntreprise = company;
    this.inputEnterprise = company.nom;
  }

  async companySearch($event) {
    const companyValue = $event.target.value;
    if (this.inputEnterprise !== this?.currentEntreprise?.nom) {
      this.companiesList = undefined;
      this.currentEntreprise.id = undefined;
      this.currentEntreprise.nom = this.inputEnterprise;
      if (companyValue.length >= 2) {
        try {
          this.searchingCompanies = true;
          let result = await this.evtService.searchCompaniesByEventId(this.maConfig.monEvenement.id, companyValue).toPromise();
          if (result && result.length) {
            this.companiesList = result;
          }
        } catch (e) {
          console.error(e);
          this.companiesList = undefined;
        } finally {
          this.searchingCompanies = false;
        }
      } else {
        this.currentEntreprise.nom = this.inputEnterprise;
        this.companiesList = undefined;
      }
    }
  }

  async validActionUser() {
    if (this.userCreate) {
      this.addUser();
    } else if (this.userUpdate) {
      this.updateUser();
    }
  }

  async askForMagicLink(user: Utilisateur) {
    const confirmation = await new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Envoyer un lien de connexion ?',
        message: 'Voulez-vous envoyer un lien de connexion à ' + user.prenomContact + " " + user.nomContact + " maintenant ?",
        buttons: [
          {
            text: this.translate.instant("help.no"),
            role: this.translate.instant("app.cancel"),
            handler: () => {
              return resolve(false);
            }
          }, {
            text: this.translate.instant("help.yes"),
            handler: async data => {
              // console.log("SendMagicLink addUser - start tel=", user.tel, "mail=", user.email);
              let loginBody = (!user.tel || user.tel.length == 0
                ? { evenementId: user.evenement.id, phone: "", mail: user.email }
                : { evenementId: user.evenement.id, phone: user.tel, mail: "" });
              try {
                const result = await this.authService.login(loginBody).toPromise();
              } catch (error) {
                // console.log("SendMagicLink addUser - error", error);
              } finally {
                // console.log("SendMagicLink addUser - finally");
              }
              // console.log("SendMagicLink addUser - end");
              return resolve(true);
            }
          }
        ]
      });
      await alert.present();
    });
  }


  async addUser() {
    this.userError = "";
    console.log(" entreprise ", this.currentEntreprise);
    if (!this.currentUser.nomContact && !this.currentUser.prenomContact) {
      this.userError = this.translate.instant("selrequest.fill");
      return;
    } else if (!this.currentUser.email && !this.currentUser.tel) {
      this.userError = this.translate.instant("selrequest.fillTel");
      return;
    } else if (this.currentUser.email && !this.common.isEmail(this.currentUser.email)) {
      this.userError = this.translate.instant("selrequest.emailno");
      return;
    } else if (this.currentUser.tel && !this.common.isMobile(this.currentUser.tel)) {
      this.userError = this.translate.instant("selrequest.phoneno");
      return;
    } else if (!this.currentUser.role) {
      this.userError = this.translate.instant("selrequest.role");
      return;
    } else if (!this.currentEntreprise.nom) {
      this.userError = this.translate.instant("selrequest.fillComp");
      return;
    } else if (!this.currentEntreprise.id) {
      const confirmation = await new Promise(async (resolve) => {
        const alert = await this.alertController.create({
          header: 'Confirmation',
          message: this.translate.instant("selrequest.createComp") + " '" + this.currentEntreprise.nom + "' ?",
          buttons: [
            {
              text: this.translate.instant("help.no"),
              role: this.translate.instant("app.cancel"),
              handler: () => {
                return resolve(false);
              }
            }, {
              text: this.translate.instant("help.yes"),
              handler: async data => {
                let newEntreprise = this.currentEntreprise;
                const ent = await this.evtService.addCompanyToEvent(newEntreprise, this.currentUser.evenement.id).toPromise();
                if (ent.id) {
                  this.currentEntreprise = ent;
                  return resolve(true);
                }
              }
            }
          ]
        });
        await alert.present();
      });
      if (!this.currentEntreprise.id) {
        this.userError = this.translate.instant("help.selComp");
        return;
      }
    }

    let creationUser: NouvelUtilisateur = {
      evenementId: this.currentUser.evenement.id,
      nomContact: this.currentUser.nomContact,
      prenomContact: this.currentUser.prenomContact,
      telephone: this.currentUser.tel,
      email: this.currentUser.email,
      role: this.currentUser.role,
      // revoir entreprise (id ou nom si nom seul : ent à créer)
      nomEntreprise: this.currentEntreprise.nom,
      entrepriseId: this.currentEntreprise.id
    };

    // CREATION USER : Récupération entreprise sélectionnée (currentEntreprise) + Entreprise à créer ? +
    // gestion Photo + renvoi magicLink (email ou sms)

    this.waiting = true;
    let createdUser = undefined;
    try {
      createdUser = await this.evtService.addUserByEventId(creationUser, this.currentUser.evenement.id).toPromise();
      if (!createdUser || isNaN(createdUser.id)) {
        this.userError = "Erreur : la création n'a été réalisée correctement";
      } else {
        this.myElementSync.next(createdUser);
        // ENVOI magicLink alert panel (SMS / EMAIL / AUCUN / LES2)
        await this.askForMagicLink(createdUser);
        await this.dismissModal();
      }
    } catch (e) {
      this.userError = "Erreur : création impossible";
      console.log(e.status, " TEXT ", e.statusText)
      console.error(e);
    } finally {
      this.waiting = false;
    }
  }

  async updateUser() {
    let updatedUser: Utilisateur = undefined;
    this.waiting = true;
    try {
      this.currentUser.entreprise = this.currentEntreprise;
      updatedUser = await this.evtService.updateUser(this.currentUser, this.currentUser.evenement.id, this.currentUser.id).toPromise();
      if (!updatedUser || isNaN(updatedUser.id)) {
        this.userError = "Erreur : modification impossible";
      } else {
        this.myElementSync.next(updatedUser);
        this.userUpdate = false;
        // await this.dismissModal();
      }
    } catch (e) {
      this.userError = "Erreur : création impossible (" + (e.error ? e.error : e.status + " - " + e.statusText) + ")";
      console.log(e.status, " TEXT ", e.statusText)
      console.error(e);
    } finally {
      this.waiting = false;
      this.searchString = "";
    }
  }

  async selectUser(e) {
    if (this.myElementSync && !this.infoGet) {
      this.myElementSync.next(e);
      await this.dismissModal();
    } else if (this.infoGet) {
      this.userDetails = e;
      // console.log(e);
      this.showDetails = true;
    }
  }

  async editUser(e) {
    console.log(" EDIT user ", e);
    this.userError = "";
    this.userUpdate = true;
    this.currentUser = e;
    this.currentEntreprise = e.entreprise;
    this.inputEnterprise = e.entreprise.nom;
    this.currentUser.evenement = this.maConfig.monEvenement;
  }

  async deleteUser(e) {
    this.userError = "";
    this.waiting = true;
    try {
      let deletedUser = undefined;
      deletedUser = await this.evtService.deleteUtilisateur(this.maConfig.monEvenement.id, e.id).toPromise();
      console.log("RETURN ", deletedUser);
      if (!deletedUser) {
        this.userError = "Erreur : Suppression impossible";
      } else {
        this.userError = "Utilisateur supprimé";
        this.loadUsers();
      }
    } catch (e) {
      this.userError = "Erreur : suppression impossible" + (e.status == 409 ? " (" + e.error + ")" : "") + ".";
      console.log(e.status, " TEXT ", e.statusText);
      console.error(e);
      this.loadUsers();
      this.cdr.detectChanges();
    } finally {
      this.waiting = false;
    }
  }
  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

  async updateSearch() {
    this.userError = "";
    const search = this.searchString.toLocaleLowerCase();
    const items = Array.from(document.getElementById("displayListUsers").children);
    this.countSearch = 0;
    items.forEach((e) => {
      const shouldShow = e.textContent.toLowerCase().indexOf(search) > -1;
      if (shouldShow) { this.countSearch++; }
      (<HTMLElement>e).style.display = shouldShow ? 'block' : 'none';
    });
  }

  sortList(field: string) {
    if (field == this.lastSortOrder) {
      this.sortDirection = !this.sortDirection;
      this.users.reverse();
    } else {
      switch (field) {
        case "firstname": {
          this.users.sort((a, b) => (a.prenomContact.toLocaleLowerCase() > b.prenomContact.toLocaleLowerCase()) ? 1 : (a.prenomContact === b.prenomContact) ? ((a.nomContact > b.nomContact) ? 1 : -1) : -1);
          break;
        }
        case "lastname": {
          this.users.sort((a, b) => (a.nomContact.toLocaleLowerCase() > b.nomContact.toLocaleLowerCase()) ? 1 : (a.nomContact === b.nomContact) ? ((a.prenomContact > b.prenomContact) ? 1 : -1) : -1);
          break;
        }
        case "company": {
          this.users.sort((a, b) => (a.entreprise.nomRecherche > b.entreprise.nomRecherche) ? 1 : (a.entreprise.nomRecherche === b.entreprise.nomRecherche) ? ((a.entreprise.id > b.entreprise.id) ? 1 : -1) : -1);
          break;
        }
      }
      this.lastSortOrder = field;
    }
  }

  async loadUsers() {
    this.loading = true;
    if (!this.maConfig) {
      this.maConfig = await this.configService.getConfig();
    }
    if (this.allUsers) {
      this.users = await this.evtService.getUsersByEventId(this.maConfig.monEvenement.id).toPromise();
    } else {
      if (this.selectedEnterprise) {
        this.users = await this.userService.getUsersByCompany(this.selectedEnterprise.id).toPromise();
      } else {
        this.users = await this.userService.searchUsersIntervenants(this.maConfig.monEvenement.id).toPromise();
      }
    }
    this.countSearch = this.users.length;
    // this.sortList();
    this.loading = false;
    this.cdr.detectChanges();
  }
  async ionViewDidEnter() {
    await this.loadUsers();
  }
  async initView() {
  }
  ngOnInit() {
    //    this.initView();
  }
}

