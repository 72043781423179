/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MediaNotification } from './mediaNotification';
import { StatutDemande } from './statutDemande';
import { Utilisateur } from './utilisateur';

export interface NotificationStatuts { 
    id?: number;
    statut?: StatutDemande;
    notif?: MediaNotification;
    utilisateur?: Utilisateur;
}