import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  Evenement,
  EvenementsService,
  RoleUtilisateur,
  Utilisateur,
} from 'src/app/server';
import { Location } from '@angular/common';

import { ModalController } from '@ionic/angular';
import { NetexpoContactPage } from 'src/app/netexpo-contact/netexpo-contact.page';
import { SelectRequestUserPage } from '../../select-request-user/select-request-user.page';

import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventHelperConfig } from 'src/app/eventHelperConfig';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SelectSpacePage } from 'src/app/select-space/select-space.page';
import { FloorplanPage } from 'src/app/floorplan/floorplan.page';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  debug = localStorage.getItem('Debug');
  langue: string;
  token: string;

  @Input()
  cfg: EventHelperConfig = undefined;

  @Input()
  currentEvt: Evenement = undefined;
  @Input()
  currentUser: Utilisateur = undefined;

  @Input()
  backButton: boolean = true;
  @Input()
  homeButton: boolean = false;
  @Input()
  backPage: string = '/';

  @Input()
  pageTitle: string = '';

  // Pass Function to child component : https://medium.com/@7hwyl/how-to-pass-a-function-to-a-child-component-in-angular-719fc3d1ee90
  @Input() helpUserFunction: (args: any) => void;

  constructor(
    private translate: TranslateService,
    public router: Router,
    public toastController: ToastController,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private evtSvc: EvenementsService,
    private _location: Location
  ) { }

  authenticated(): boolean {
    return (
      this.cfg && this.cfg.monUtilisateur && this.cfg.monUtilisateur.id > 0
    );
    // const result = sessionStorage.getItem('AccessGranted');
    // return (!result ? false : (result == "true") ? true : false);
  }

  elevatedUser(): boolean {
    if (this.currentUser) {
      if (
        this.currentUser.role == RoleUtilisateur.Administrateur ||
        this.currentUser.role == RoleUtilisateur.Organisateur
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onHelpPage(): boolean {
    if (this.router.url.startsWith('/help')) {
      return true;
    } else {
      return false;
    }
  }

  switchToList() {
    this.router.navigate(['/list']);
  }

  switchToHelp() {
    this.router.navigate(['/help']);
  }

  initView() {
    if (localStorage.hasOwnProperty('Language')) {
      this.langue = localStorage.getItem('Language');
      this.translate.setDefaultLang(this.langue);
    }
  }

  changeCfg() {
    this.token = localStorage.getItem('Token');
    if (this.authenticated()) {
      this.currentUser = this.cfg.monUtilisateur;
      this.currentEvt = this.cfg.monEvenement;
    } else {
      if (this.cfg && this.cfg.monEvenement) {
        this.currentEvt = this.cfg.monEvenement;
      }
    }
  }

  ngOnInit() {
    this.initView();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['cfg']) {
      this.changeCfg();
    } else if (changes['currentEvt'] || changes['currentUser']) {
      this.token = localStorage.getItem('Token');
    } else {
    }
  }

  public async switchLanguage() {
    const lang = localStorage.hasOwnProperty("Language") ? localStorage.getItem("Language") : (navigator.language ? navigator.language.substring(0, 2) : "fr");
    console.log(" Current Lang ", lang);
    this.langue = lang == 'fr' ? 'en' : 'fr';
    // console.log(" Default Lang ", this.translate.getDefaultLang());
    this.translate.setDefaultLang(this.langue);
    localStorage.setItem('Language', this.langue);
    console.log(' [Header] Switch to Language ', this.langue);
  }

  async presentToast(type: string, msg: string) {
    const icon = 'warning';
    // const header = this.translate.instant('app.warning');
    // const header = this.translate.instant('app.warning');
    const toast = await this.toastController.create({
      header: type,
      message: msg,
      icon: icon,
      position: 'bottom',
      cssClass: 'toastCustom',
      duration: 5000,
      buttons: [
        {
          side: 'end',
          icon: 'log-out-outline',
          text: this.translate.instant('home.logout'),
          handler: () => {
            localStorage.removeItem('Token');
            sessionStorage.removeItem('User');
            localStorage.removeItem('SpaceList');
            localStorage.removeItem('SpaceUpdate');
            this.cfg = undefined;
            this.currentEvt = undefined;
            this.currentUser = undefined;
            this.router.navigateByUrl('/authentification');
          },
        },
      ],
    });
    console.log(' header LOGOUT : ', this.translate.instant('home.logout'));
    toast.present();
  }

  async changeUser() {
    await this.presentToast(
      this.translate.instant('app.feature_user'),
      this.translate.instant('app.logout_msg')
    );
  }

  async showUsers() {
    const myElementSync = new Subject();
    let showAllUsers: boolean = true;
    let infoOnClick = true;
    let title: string = this.translate.instant('Annuaire');
    // let title: string =this.translate.instant('app.logout_selUser')
    const modal = await this.modalCtrl.create({
      component: SelectRequestUserPage,
      cssClass: 'largeModal',
      handle: false,
      componentProps: {
        myElementSync,
        infoGet: infoOnClick,
        allUsers: showAllUsers,
        title: title,
      },
    });
    await modal.present();
  }

  async showSpaces() {
    const myElementSync = new Subject();
    let title: string = this.translate.instant('Liste des espaces');
    // let title: string =this.translate.instant('app.logout_selUser')
    const modal = await this.modalCtrl.create({
      component: SelectSpacePage,
      cssClass: 'largeModal',
      handle: false,
      componentProps: {
        myElementSync,
        // infoGet: infoOnClick,
        // allUsers: showAllUsers,
        title: title,
      },
    });
    await modal.present();
  }

  async showDocuments() {
    this.router.navigate(['/doc']);
    // const toast = await this.toastController.create({
    //   message: "Le recueil de documents n'est pas encore accessible",
    //   duration: 1500,
    //   position: 'bottom'
    // });
    // await toast.present();
  }

  async showFloorplan() {
    console.log(' show floorplan');
    const modal = await this.modalCtrl.create({
      component: FloorplanPage,
      cssClass: 'largeModal',
      // componentProps: { plan: 'Pav1', cfg: this.cfg },
      // workaround suppr Pav1 par défaut à remplacer par nom plan ?
      componentProps: { plan: '', cfg: this.cfg },
    });
    modal.present();
  }

  async contactNetexpo() {
    console.log('contact Netexpo');
    const modal = await this.modalCtrl.create({
      component: NetexpoContactPage,
      breakpoints: [0, 0.8],
      initialBreakpoint: 0.8,
      handle: false,
    });
    await modal.present();
  }
  goBackPage() {
    this._location.back();
  }
  async userSettings() {
    console.log('header userSettings CLICKED from ', this.router.url);
    if (this.router.url.startsWith('/help')) {
      // this.router.navigateByUrl("/help");
      this.helpUserFunction('logoutFromHeader');
    } else {
      if (localStorage.getItem('Token')) {
        const icon = 'warning';
        const header = this.translate.instant('app.warning');
        const toast = await this.toastController.create({
          // header: header,
          message: this.translate.instant('app.logout_msg'),
          icon: icon,
          position: 'bottom',
          cssClass: 'toastCustom',
          duration: 5000,
          buttons: [
            {
              side: 'end',
              icon: 'log-out-outline',
              text: this.translate.instant('home.logout'),
              handler: () => {
                console.log('LOGOUT CLICKED from ', this.router.url);
                localStorage.removeItem('Token');
                sessionStorage.removeItem('User');
                localStorage.removeItem('SpaceList');
                localStorage.removeItem('SpaceUpdate');
                this.cfg = undefined;
                this.currentEvt = undefined;
                this.currentUser = undefined;
                this.router.navigateByUrl('/authentification');
              },
            },
            {
              side: 'end',
              icon: 'settings-outline',
              text: 'settings',
              handler: () => {
                console.log('SETTINGS CLICKED from ', this.router.url);
                // localStorage.removeItem("Token");
                // this.cfg = undefined;
                // this.currentEvt = undefined;
                // this.currentUser = undefined;
                this.router.navigateByUrl('/user');
              },
            },
          ],
        });
        console.log(' LOGOUT : ', this.translate.instant('home.logout'));
        toast.present();
      } else {
        // ALREADY REGISTERED
        console.log('AlreadyRegistered CLICKED from ', this.router.url);
        if (this.router.url.startsWith('/help')) {
          // this.router.navigateByUrl("/help");
          this.helpUserFunction('logoutFromHeader');
        }
      }
    }
  }
}
