import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-zoom-pj',
  templateUrl: './zoom-pj.page.html',
  styleUrls: ['./zoom-pj.page.scss'],
})
export class ZoomPjPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input('img') img: any;
  @Input('pjs') pjs: any;
  @Input('demande') demande: number;
  sliderOpts: {
    zoom: boolean; initialSlide: any;
  };
  prevbool = true;
  nextbool = true;



  constructor(private modalController: ModalController) { }
  ngOnInit() {
    this.sliderOpts = {
      zoom: true,
      initialSlide: this.img
    };
    console.log('pjs',this.pjs)

  }

  getBaseUrl() {
    return environment.API_BASE_PATH;
  }

  getAttachmentUrl(id: number) {
    return this.getBaseUrl() + '/demandes/' + this.demande + '/chat/attachment/' + id
  }


  async ionViewDidEnter() {
      this.slides.update();
      await this.slidesChange();

    }

  async slidesChange() {
      this.prevbool = true;
      this.nextbool = true;
      this.nextbool = await this.isSlidesEnd();
      this.prevbool = await this.isSlidesBeginning();
    }

  async isSlidesBeginning(): Promise < boolean > {
      return await this.slides.isBeginning()
    }
  async isSlidesEnd(): Promise < boolean > {
      return await this.slides.isEnd()
    }

  async zoom(zoomIn: boolean) {
      const slider = await this.slides.getSwiper();
      const zoom = slider.zoom;
      zoomIn ? zoom.in() : zoom.out();
    }


    close() {
      this.modalController.dismiss();
    }
  async prev() {
      await this.slides.slidePrev()
      await this.slidesChange();

    }
  async next() {
      await this.slides.slideNext()
      await this.slidesChange();

    }

  }