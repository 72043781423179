/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Demande } from '../model/demande';
import { Document } from '../model/document';
import { Entreprise } from '../model/entreprise';
import { Espace } from '../model/espace';
import { Evenement } from '../model/evenement';
import { NouveauDocument } from '../model/nouveauDocument';
import { NouveauTypeDemande } from '../model/nouveauTypeDemande';
import { NouvelEspace } from '../model/nouvelEspace';
import { NouvelEvenement } from '../model/nouvelEvenement';
import { NouvelUtilisateur } from '../model/nouvelUtilisateur';
import { NouvelleEntreprise } from '../model/nouvelleEntreprise';
import { StatutDemande } from '../model/statutDemande';
import { TypeDemande } from '../model/typeDemande';
import { Utilisateur } from '../model/utilisateur';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EvenementsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une entreprise pour un évènement
     * EX_34
     * @param body Nouvelle entreprise créée
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompanyToEvent(body: NouvelleEntreprise, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Entreprise>;
    public addCompanyToEvent(body: NouvelleEntreprise, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Entreprise>>;
    public addCompanyToEvent(body: NouvelleEntreprise, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Entreprise>>;
    public addCompanyToEvent(body: NouvelleEntreprise, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCompanyToEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addCompanyToEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Entreprise>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/entreprises`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter un document à l&#x27;évènement
     * EX_75
     * @param body Nouveau document à ajouter
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDocumentToEvent(body: NouveauDocument, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Document>;
    public addDocumentToEvent(body: NouveauDocument, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Document>>;
    public addDocumentToEvent(body: NouveauDocument, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Document>>;
    public addDocumentToEvent(body: NouveauDocument, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDocumentToEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addDocumentToEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Document>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/document`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un nouvel événement
     * EX_3
     * @param body Evénement à créer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addEvent(body: NouvelEvenement, observe?: 'body', reportProgress?: boolean): Observable<Evenement>;
    public addEvent(body: NouvelEvenement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Evenement>>;
    public addEvent(body: NouvelEvenement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Evenement>>;
    public addEvent(body: NouvelEvenement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Evenement>('post',`${this.basePath}/evenements`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un utilisateur demandeur pour un évenement.
     * EX_73
     * @param body Utilisateur à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRequestingUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public addRequestingUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public addRequestingUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public addRequestingUserByEvent(body: NouvelUtilisateur, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addRequestingUserByEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addRequestingUserByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/demandeur`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un nouvel espace pour un événement
     * EX_14
     * @param body Nouvel espace à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSpacesForEvent(body: NouvelEspace, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Espace>;
    public addSpacesForEvent(body: NouvelEspace, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Espace>>;
    public addSpacesForEvent(body: NouvelEspace, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Espace>>;
    public addSpacesForEvent(body: NouvelEspace, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addSpacesForEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addSpacesForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Espace>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un nouveau type de demande pour un événement
     * EX_20
     * @param body Nouveau type de demande à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTypeDemandesByEventId(body: NouveauTypeDemande, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<TypeDemande>;
    public addTypeDemandesByEventId(body: NouveauTypeDemande, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TypeDemande>>;
    public addTypeDemandesByEventId(body: NouveauTypeDemande, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TypeDemande>>;
    public addTypeDemandesByEventId(body: NouveauTypeDemande, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addTypeDemandesByEventId.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addTypeDemandesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TypeDemande>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/typeDemandes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un utilisateur intervenant pour un évenement.
     * EX_39 EX_40
     * @param body Utilisateur à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public addUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public addUserByEvent(body: NouvelUtilisateur, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public addUserByEvent(body: NouvelUtilisateur, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUserByEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addUserByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/intervenant`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un utilisateur pour un évenement.
     * EX_117
     * @param body Utilisateur à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public addUserByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public addUserByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public addUserByEventId(body: NouvelUtilisateur, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUserByEventId.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addUserByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/utilisateur`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un utilisateur générique pour une entreprise pour un évenement.
     * EX_121
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param entrepriseNom Nom de l&#x27;entreprise
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserEntrepriseByEventId(evenementId: number, entrepriseNom: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public addUserEntrepriseByEventId(evenementId: number, entrepriseNom: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public addUserEntrepriseByEventId(evenementId: number, entrepriseNom: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public addUserEntrepriseByEventId(evenementId: number, entrepriseNom: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addUserEntrepriseByEventId.');
        }

        if (entrepriseNom === null || entrepriseNom === undefined) {
            throw new Error('Required parameter entrepriseNom was null or undefined when calling addUserEntrepriseByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/entreprise/${encodeURIComponent(String(entrepriseNom))}/contact`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un utilisateur organisateur pour un évenement.
     * EX_10
     * @param body Utilisateur à créer
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserOrganizerByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public addUserOrganizerByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public addUserOrganizerByEventId(body: NouvelUtilisateur, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public addUserOrganizerByEventId(body: NouvelUtilisateur, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUserOrganizerByEventId.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling addUserOrganizerByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/organisateur`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un document à l&#x27;évènement
     * EX_76
     * @param evenementId identifiant unique d&#x27;un evenement
     * @param documentId identifiant unique d&#x27;un document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletDocumentToEvent(evenementId: number, documentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletDocumentToEvent(evenementId: number, documentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletDocumentToEvent(evenementId: number, documentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletDocumentToEvent(evenementId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling deletDocumentToEvent.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deletDocumentToEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un espace pour un événement
     * EX_110
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param espaceId Identifiant unique de l&#x27;espace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEspace(evenementId: number, espaceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEspace(evenementId: number, espaceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEspace(evenementId: number, espaceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEspace(evenementId: number, espaceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling deleteEspace.');
        }

        if (espaceId === null || espaceId === undefined) {
            throw new Error('Required parameter espaceId was null or undefined when calling deleteEspace.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces/${encodeURIComponent(String(espaceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un type de demande pour un événement
     * EX_111
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param typeDemandeId Identifiant unique du type de demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTypeDemande(evenementId: number, typeDemandeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTypeDemande(evenementId: number, typeDemandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTypeDemande(evenementId: number, typeDemandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTypeDemande(evenementId: number, typeDemandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling deleteTypeDemande.');
        }

        if (typeDemandeId === null || typeDemandeId === undefined) {
            throw new Error('Required parameter typeDemandeId was null or undefined when calling deleteTypeDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/typeDemandes/${encodeURIComponent(String(typeDemandeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un utilisateur pour un événement
     * EX_119
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUtilisateur(evenementId: number, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUtilisateur(evenementId: number, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUtilisateur(evenementId: number, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUtilisateur(evenementId: number, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling deleteUtilisateur.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling deleteUtilisateur.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/utilisateurs/${encodeURIComponent(String(utilisateurId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les événements
     * EX_1
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEvents(observe?: 'body', reportProgress?: boolean): Observable<Array<Evenement>>;
    public getAllEvents(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Evenement>>>;
    public getAllEvents(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Evenement>>>;
    public getAllEvents(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Evenement>>('get',`${this.basePath}/evenements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les entreprises pour un événement
     * EX_33
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompaniesByEvent(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Entreprise>>;
    public getCompaniesByEvent(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Entreprise>>>;
    public getCompaniesByEvent(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Entreprise>>>;
    public getCompaniesByEvent(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getCompaniesByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Entreprise>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/entreprises`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consulter une demande pour un événement
     * EX_30
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param demandeId Identifiant unique de la demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDemandeForEvent(evenementId: number, demandeId: number, observe?: 'body', reportProgress?: boolean): Observable<Demande>;
    public getDemandeForEvent(evenementId: number, demandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Demande>>;
    public getDemandeForEvent(evenementId: number, demandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Demande>>;
    public getDemandeForEvent(evenementId: number, demandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getDemandeForEvent.');
        }

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling getDemandeForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Demande>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/demandes/${encodeURIComponent(String(demandeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les demandes pour un événement
     * EX_24
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDemandesByEventId(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Demande>>;
    public getDemandesByEventId(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Demande>>>;
    public getDemandesByEventId(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Demande>>>;
    public getDemandesByEventId(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getDemandesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Demande>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/demandes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Télécharger un document d&#x27;un évènement
     * EX_78
     * @param evenementId identifiant unique d&#x27;un evenement
     * @param documentId identifiant unique d&#x27;un document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentForEvent(evenementId: number, documentId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getDocumentForEvent(evenementId: number, documentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getDocumentForEvent(evenementId: number, documentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getDocumentForEvent(evenementId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getDocumentForEvent.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/document/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les documents d&#x27;un évènement
     * EX_77
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentsForEvent(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Document>>;
    public getDocumentsForEvent(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Document>>>;
    public getDocumentsForEvent(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Document>>>;
    public getDocumentsForEvent(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getDocumentsForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Document>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/documents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupérer les détails d&#x27;un événement.
     * EX_2
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventById(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Evenement>;
    public getEventById(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Evenement>>;
    public getEventById(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Evenement>>;
    public getEventById(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getEventById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Evenement>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupérer les détails d&#x27;un événement depuis un nom court (QRCode)
     * EX_NEW
     * @param shortname Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventByShortName(shortname: string, observe?: 'body', reportProgress?: boolean): Observable<Evenement>;
    public getEventByShortName(shortname: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Evenement>>;
    public getEventByShortName(shortname: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Evenement>>;
    public getEventByShortName(shortname: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shortname === null || shortname === undefined) {
            throw new Error('Required parameter shortname was null or undefined when calling getEventByShortName.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Evenement>('get',`${this.basePath}/evenements/short/${encodeURIComponent(String(shortname))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les entreprises orga ou presta pour un événement
     * EX_120
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPestatairesByEvent(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Entreprise>>;
    public getPestatairesByEvent(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Entreprise>>>;
    public getPestatairesByEvent(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Entreprise>>>;
    public getPestatairesByEvent(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getPestatairesByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Entreprise>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/prestataires`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtenir le détail de l&#x27;espace d&#x27;un événement
     * EX_105
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param espaceId Identifiant unique de l&#x27;espace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpaceDetailsByEvent(evenementId: number, espaceId: number, observe?: 'body', reportProgress?: boolean): Observable<Espace>;
    public getSpaceDetailsByEvent(evenementId: number, espaceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Espace>>;
    public getSpaceDetailsByEvent(evenementId: number, espaceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Espace>>;
    public getSpaceDetailsByEvent(evenementId: number, espaceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getSpaceDetailsByEvent.');
        }

        if (espaceId === null || espaceId === undefined) {
            throw new Error('Required parameter espaceId was null or undefined when calling getSpaceDetailsByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Espace>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces/${encodeURIComponent(String(espaceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les espaces d&#x27;un événement
     * EX_104
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpacesForEvent(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Espace>>;
    public getSpacesForEvent(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Espace>>>;
    public getSpacesForEvent(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Espace>>>;
    public getSpacesForEvent(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getSpacesForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Espace>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les types de demandes d&#x27;un événement
     * EX_22
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTypeDemandesByEventId(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TypeDemande>>;
    public getTypeDemandesByEventId(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TypeDemande>>>;
    public getTypeDemandesByEventId(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TypeDemande>>>;
    public getTypeDemandesByEventId(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getTypeDemandesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TypeDemande>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/typeDemandes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consulter un utilisateur d&#x27;un événement
     * EX_9
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserByEventId(evenementId: number, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public getUserByEventId(evenementId: number, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public getUserByEventId(evenementId: number, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public getUserByEventId(evenementId: number, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getUserByEventId.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling getUserByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Utilisateur>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/utilisateurs/${encodeURIComponent(String(utilisateurId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupérer les utilisateurs d&#x27;un événement.
     * EX_8
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersByEventId(evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateur>>;
    public getUsersByEventId(evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateur>>>;
    public getUsersByEventId(evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateur>>>;
    public getUsersByEventId(evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling getUsersByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Utilisateur>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/utilisateurs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les entreprises d&#x27;un événement correspondant à la recherche
     * EX_16b
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param recherche Nom recherché
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompaniesByEventId(evenementId: number, recherche: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Entreprise>>;
    public searchCompaniesByEventId(evenementId: number, recherche: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Entreprise>>>;
    public searchCompaniesByEventId(evenementId: number, recherche: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Entreprise>>>;
    public searchCompaniesByEventId(evenementId: number, recherche: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchCompaniesByEventId.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling searchCompaniesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Entreprise>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/entreprises/recherche/${encodeURIComponent(String(recherche))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les demandes pour un événement
     * EX_24
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param statut Statuts recherchés
     * @param createur Identifiant du ou des createurs des demandes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDemandesByEventId(evenementId: number, statut?: Array<StatutDemande>, createur?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<Demande>>;
    public searchDemandesByEventId(evenementId: number, statut?: Array<StatutDemande>, createur?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Demande>>>;
    public searchDemandesByEventId(evenementId: number, statut?: Array<StatutDemande>, createur?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Demande>>>;
    public searchDemandesByEventId(evenementId: number, statut?: Array<StatutDemande>, createur?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchDemandesByEventId.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statut) {
            statut.forEach((element) => {
                queryParameters = queryParameters.append('statut', <any>element);
            })
        }
        if (createur) {
            createur.forEach((element) => {
                queryParameters = queryParameters.append('createur', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Demande>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/demandesSearch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lister les espaces d&#x27;un événement correspondant à la recherche
     * EX_16
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param recherche Nom recherché
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSpacesByEventId(evenementId: number, recherche: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Espace>>;
    public searchSpacesByEventId(evenementId: number, recherche: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Espace>>>;
    public searchSpacesByEventId(evenementId: number, recherche: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Espace>>>;
    public searchSpacesByEventId(evenementId: number, recherche: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchSpacesByEventId.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling searchSpacesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Espace>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces/recherche/${encodeURIComponent(String(recherche))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recherche des demandes en plein texte
     * EX_28
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param texteRecherche texte à chercher
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTextDemandesByEventId(evenementId: number, texteRecherche: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Demande>>;
    public searchTextDemandesByEventId(evenementId: number, texteRecherche: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Demande>>>;
    public searchTextDemandesByEventId(evenementId: number, texteRecherche: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Demande>>>;
    public searchTextDemandesByEventId(evenementId: number, texteRecherche: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling searchTextDemandesByEventId.');
        }

        if (texteRecherche === null || texteRecherche === undefined) {
            throw new Error('Required parameter texteRecherche was null or undefined when calling searchTextDemandesByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Demande>>('get',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/demandes/recherche/${encodeURIComponent(String(texteRecherche))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier une entreprise pour un évènement
     * EX_35 Ex_36
     * @param body Entreprise à modifier
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param entrepriseId Identifiant unique de l&#x27;entreprise
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyByEvent(body: Entreprise, evenementId: number, entrepriseId: number, observe?: 'body', reportProgress?: boolean): Observable<Entreprise>;
    public updateCompanyByEvent(body: Entreprise, evenementId: number, entrepriseId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Entreprise>>;
    public updateCompanyByEvent(body: Entreprise, evenementId: number, entrepriseId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Entreprise>>;
    public updateCompanyByEvent(body: Entreprise, evenementId: number, entrepriseId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCompanyByEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateCompanyByEvent.');
        }

        if (entrepriseId === null || entrepriseId === undefined) {
            throw new Error('Required parameter entrepriseId was null or undefined when calling updateCompanyByEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Entreprise>('put',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/entreprises/${encodeURIComponent(String(entrepriseId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier les détails d&#x27;un événement.
     * EX_6 EX_7 EX_19
     * @param body Evénement à modifier (les dates de création et de la modification ne sont pas prises en compte)
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEvent(body: Evenement, evenementId: number, observe?: 'body', reportProgress?: boolean): Observable<Evenement>;
    public updateEvent(body: Evenement, evenementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Evenement>>;
    public updateEvent(body: Evenement, evenementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Evenement>>;
    public updateEvent(body: Evenement, evenementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Evenement>('put',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier les détails d&#x27;un espace d&#x27;un événement.
     * EX_17
     * @param body Espace à modifier
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param espaceId Identifiant unique de l&#x27;espace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpacesForEvent(body: Espace, evenementId: number, espaceId: number, observe?: 'body', reportProgress?: boolean): Observable<Espace>;
    public updateSpacesForEvent(body: Espace, evenementId: number, espaceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Espace>>;
    public updateSpacesForEvent(body: Espace, evenementId: number, espaceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Espace>>;
    public updateSpacesForEvent(body: Espace, evenementId: number, espaceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSpacesForEvent.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateSpacesForEvent.');
        }

        if (espaceId === null || espaceId === undefined) {
            throw new Error('Required parameter espaceId was null or undefined when calling updateSpacesForEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Espace>('put',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/espaces/${encodeURIComponent(String(espaceId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier les détails d&#x27;un type de demande d&#x27;un événement.
     * EX_21
     * @param body Type de demande à modifier
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param typeDemandeId Identifiant unique du type de demande
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTypeDemandeByEventId(body: TypeDemande, evenementId: number, typeDemandeId: number, observe?: 'body', reportProgress?: boolean): Observable<TypeDemande>;
    public updateTypeDemandeByEventId(body: TypeDemande, evenementId: number, typeDemandeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TypeDemande>>;
    public updateTypeDemandeByEventId(body: TypeDemande, evenementId: number, typeDemandeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TypeDemande>>;
    public updateTypeDemandeByEventId(body: TypeDemande, evenementId: number, typeDemandeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTypeDemandeByEventId.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateTypeDemandeByEventId.');
        }

        if (typeDemandeId === null || typeDemandeId === undefined) {
            throw new Error('Required parameter typeDemandeId was null or undefined when calling updateTypeDemandeByEventId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TypeDemande>('put',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/typeDemandes/${encodeURIComponent(String(typeDemandeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifier les détails d&#x27;un utilisateur pour un événement.
     * EX_12, EX_42, EX_43, EX_84, EX_85
     * @param body Utilisateur à modifier
     * @param evenementId Identifiant unique de l&#x27;événement
     * @param utilisateurId Identifiant unique de l&#x27;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(body: Utilisateur, evenementId: number, utilisateurId: number, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public updateUser(body: Utilisateur, evenementId: number, utilisateurId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public updateUser(body: Utilisateur, evenementId: number, utilisateurId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public updateUser(body: Utilisateur, evenementId: number, utilisateurId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUser.');
        }

        if (evenementId === null || evenementId === undefined) {
            throw new Error('Required parameter evenementId was null or undefined when calling updateUser.');
        }

        if (utilisateurId === null || utilisateurId === undefined) {
            throw new Error('Required parameter utilisateurId was null or undefined when calling updateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('put',`${this.basePath}/evenements/${encodeURIComponent(String(evenementId))}/utilisateurs/${encodeURIComponent(String(utilisateurId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
