/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CommentairesService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Supprimer une PJ à un commentaire
     * EX_109
     * @param demandeId identifiant unique d&#x27;une demande
     * @param commentaireId identifiant unique d&#x27;un commentaire
     * @param pieceJointeId identifiant unique d&#x27;une pièce jointe
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePieceJointeToComment(demandeId: number, commentaireId: number, pieceJointeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePieceJointeToComment(demandeId: number, commentaireId: number, pieceJointeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePieceJointeToComment(demandeId: number, commentaireId: number, pieceJointeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePieceJointeToComment(demandeId: number, commentaireId: number, pieceJointeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling deletePieceJointeToComment.');
        }

        if (commentaireId === null || commentaireId === undefined) {
            throw new Error('Required parameter commentaireId was null or undefined when calling deletePieceJointeToComment.');
        }

        if (pieceJointeId === null || pieceJointeId === undefined) {
            throw new Error('Required parameter pieceJointeId was null or undefined when calling deletePieceJointeToComment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/commentaires/${encodeURIComponent(String(commentaireId))}/pieceJointe/${encodeURIComponent(String(pieceJointeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer une PJ à une demande
     * EX_108
     * @param demandeId identifiant unique d&#x27;une demande
     * @param pieceJointeId identifiant unique d&#x27;une pièce jointe
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePieceJointeToDemande(demandeId: number, pieceJointeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePieceJointeToDemande(demandeId: number, pieceJointeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePieceJointeToDemande(demandeId: number, pieceJointeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePieceJointeToDemande(demandeId: number, pieceJointeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeId === null || demandeId === undefined) {
            throw new Error('Required parameter demandeId was null or undefined when calling deletePieceJointeToDemande.');
        }

        if (pieceJointeId === null || pieceJointeId === undefined) {
            throw new Error('Required parameter pieceJointeId was null or undefined when calling deletePieceJointeToDemande.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/demandes/${encodeURIComponent(String(demandeId))}/pieceJointe/${encodeURIComponent(String(pieceJointeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
