import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH } from './server';
import { environment } from 'src/environments/environment';
import { CommonNetexpoModule } from './common-netexpo/common-netexpo.module';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { TokenInterceptor } from './token.interceptor';
import { NotificationService } from './notification.service';

import { initializeApp } from "firebase/app";
// import { SecuredImagePipe } from './secured-image.pipe';
initializeApp(environment.firebase);

@NgModule({
    // declarations: [AppComponent, SecuredImagePipe],
    declarations: [AppComponent],
    imports: [BrowserModule,
        QRCodeModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        // Ajout i18n NGX Translate 
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
        }),
        // Gestion API Event'Helper
        ApiModule,
        // Module Commun Netexpo
        CommonNetexpoModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        NotificationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
