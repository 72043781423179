/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type StatutDemande = 'newdemand' | 'validated' | 'affected' | 'confirmed' | 'closed' | 'aborted';

export const StatutDemande = {
    Newdemand: 'newdemand' as StatutDemande,
    Validated: 'validated' as StatutDemande,
    Affected: 'affected' as StatutDemande,
    Confirmed: 'confirmed' as StatutDemande,
    Closed: 'closed' as StatutDemande,
    Aborted: 'aborted' as StatutDemande
};