/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NouvelUtilisateur } from './nouvelUtilisateur';
import { StatutDemande } from './statutDemande';

export interface ParamDemande { 
    evenementId: number;
    espaceId: number;
    typeDemandeId?: number;
    createurId?: number;
    createur?: NouvelUtilisateur;
    titre?: string;
    dateDemande?: string;
    description?: string;
    statut?: StatutDemande;
    intervenantId?: number;
}