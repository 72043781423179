/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RoleUtilisateur = 'organisateur' | 'intervenant' | 'demandeur' | 'administrateur';

export const RoleUtilisateur = {
    Organisateur: 'organisateur' as RoleUtilisateur,
    Intervenant: 'intervenant' as RoleUtilisateur,
    Demandeur: 'demandeur' as RoleUtilisateur,
    Administrateur: 'administrateur' as RoleUtilisateur
};