/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Demande } from './demande';
import { Document } from './document';
import { Entreprise } from './entreprise';
import { Espace } from './espace';
import { TypeDemande } from './typeDemande';
import { Utilisateur } from './utilisateur';

export interface Evenement { 
    id?: number;
    nom?: string;
    nomCourt?: string;
    dateDebut?: string;
    dateFin?: string;
    dateCreation?: string;
    dateModification?: string;
    actif?: boolean;
    logo?: string;
    entreprises?: Array<Entreprise>;
    espaces?: Array<Espace>;
    utilisateurs?: Array<Utilisateur>;
    typeDemandes?: Array<TypeDemande>;
    demandes?: Array<Demande>;
    documentEvenement?: Array<Document>;
}