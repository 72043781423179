import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthentificationService } from './api/authentification.service';
import { CommentairesService } from './api/commentaires.service';
import { DemandesService } from './api/demandes.service';
import { EntreprisesService } from './api/entreprises.service';
import { EvenementsService } from './api/evenements.service';
import { TestService } from './api/test.service';
import { UtilisateursService } from './api/utilisateurs.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthentificationService,
    CommentairesService,
    DemandesService,
    EntreprisesService,
    EvenementsService,
    TestService,
    UtilisateursService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
