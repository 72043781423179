import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, Query, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConfigService } from '../config.service';
import { EventHelperConfig } from '../eventHelperConfig';
import { Espace, NouvelEspace, EvenementsService } from '../server';
import { IonList } from '@ionic/angular';
@Component({
  selector: 'app-select-space',
  templateUrl: './select-space.page.html',
  styleUrls: ['./select-space.page.scss'],
})
export class SelectSpacePage implements OnInit, AfterViewChecked {
  @ViewChild('liste') liste: IonList;

  @Input() myElementSync: Subject<Espace>;
  @Input() title: string;

  // selection du searchbar
  // @ViewChild('searchbar') set searchbar(s: IonSearchbar) {
  //   if (s) {
  //     this.focusBar = s;
  //     console.log("FOCUS BAR ", "found", "SET FOCUS");
  //     setTimeout(function () { this.focusBar.setFocus(); }, 5000);
  //   }
  // };
  // mathieu focus
  // focusBar;

  // Focus sur le searchbar ; https://stackoverflow.com/questions/42693567/not-been-able-to-set-focus-to-my-ion-input-as-i-enter-the-page
  // exemple : https://plnkr.co/edit/1UzJDV?p=preview&preview 
  @ViewChild('searchbar') mySearchBar;
  needsFocus: boolean;
  ionViewDidEnter() {
    this.needsFocus = true;
  }
  ngAfterViewChecked(): void {
    setTimeout(() => {
      if (this.needsFocus && this.mySearchBar) {
        this.needsFocus = false;
        this.mySearchBar.setFocus();
        this.cdr.detectChanges();
      }
    }, 500);
  }

  loading = true;
  waiting = false;
  spaceError = "";
  countSearch: number = 0;
  spaceCreate = false;
  spaceUpdate = false;
  maConfig: EventHelperConfig;
  spaces: Espace[];
  spacesDisplay: Espace[];
  currentSpace: Espace;
  searchString: string = "";

  constructor(private evtService: EvenementsService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private router: Router,
    private translate: TranslateService) {
  }
  // mathieu set focus après demiseconde
  async ngAfterViewInit() {
    await this.initView();
    // if (this.focusBar) {
    //   console.log("FOCUS BAR ", this.focusBar, "FOUND TIMEOUT");
    //   setTimeout(() => {
    //     console.log("FOCUS BAR ", this.focusBar, "SET FOCUS");
    //     this.focusBar.setFocus();
    //   }, 5000);
    // } else {
    //   console.log("FOCUS BAR ", this.focusBar, "NOT FOUND");
    // }
    // setTimeout(() => {
    //   const searchbar = document.getElementById('searchbar');
    //   searchbar.focus();
    // }, 5000);
  }

  createSpace() {
    this.spaceError = "";
    this.spaceCreate = true;
    this.currentSpace = {
      evenement: this.maConfig.monEvenement,
      hall: "",
      numeroStand: "",
      nom: "",
      enseigne: "",
    };
  }

  cancelActionSpace() {
    this.spaceCreate = false;
    this.spaceUpdate = false;
  }

  async validActionSpace() {
    if (this.spaceCreate) {
      this.addSpace();
    } else if (this.spaceUpdate) {
      this.updateSpace();
    }
  }

  async addSpace() {
    // CODE = strip space / keep lettre + chiffre /
    console.log(" addSpace NEWSPACE ", this.currentSpace);
    let generatedCode = (this.currentSpace.hall.toLocaleUpperCase().replace("HALL", "H")
      + this.currentSpace.numeroStand.toLocaleUpperCase())
      .replace(/[^A-Z0-9]/, '');

    let creationEspace: NouvelEspace = {
      evenementId: this.currentSpace.evenement.id,
      hall: this.currentSpace.hall,
      nom: this.currentSpace.nom,
      enseigne: this.currentSpace.enseigne,
      numeroStand: this.currentSpace.numeroStand,
      code: generatedCode
    };

    this.waiting = true;
    let createdSpace = undefined;
    try {
      createdSpace = await this.evtService.addSpacesForEvent(creationEspace, this.currentSpace.evenement.id).toPromise();
      if (!createdSpace || isNaN(createdSpace.id)) {
        this.spaceError = "Erreur : la création n'a pas été réalisée correctement";
      } else {
        this.myElementSync.next(createdSpace);
        await this.dismissModal();
        // force full reload cache
        this.reloadSpaces();
      }
    } catch (e) {
      this.spaceError = "Erreur : création impossible (" + (e.error ? e.error : e.status + " - " + e.statusText) + ")";
      console.log(e.status, " TEXT ", e.statusText)
      console.error(e);
    } finally {
      this.waiting = false;
    }
  }

  async updateSpace() {
    let updatedSpace: Espace = undefined;
    this.waiting = true;
    try {
      console.log("BEFORE ", this.currentSpace);
      console.log("BEFORE ", this.currentSpace.evenement);
      updatedSpace = await this.evtService.updateSpacesForEvent(this.currentSpace, this.currentSpace.evenement.id, this.currentSpace.id).toPromise();
      console.log("RETURN ", updatedSpace);
      if (!updatedSpace || isNaN(updatedSpace.id)) {
        this.spaceError = "Erreur : modification impossible";
      } else {
        this.myElementSync.next(updatedSpace);
        this.spaceUpdate = false;
        // await this.dismissModal();
        // force full reload cache
        this.reloadSpaces();
      }
    } catch (e) {
      this.spaceError = "Erreur : création impossible (" + (e.error ? e.error : e.status + " - " + e.statusText) + ")";
      console.log(e.status, " TEXT ", e.statusText)
      console.error(e);
    } finally {
      this.waiting = false;
    }
  }

  async selectSpace(e) {
    console.log(" SPACE selected ", e.numeroStand);
    this.myElementSync.next(e);
    await this.dismissModal();
  }

  async editSpace(e) {
    console.log("Edit space ", e);
    this.spaceError = "";
    this.spaceUpdate = true;
    this.currentSpace = e;
    this.currentSpace.evenement = this.maConfig.monEvenement;
    // this.currentSpace = {
    //   evenement: this.maConfig.monEvenement,
    //   hall: e,
    //   numeroStand: "",
    //   enseigne: "",
    // };
  }

  async deleteSpace(e) {
    console.log("Delete space ", e);
    this.spaceError = "";
    this.waiting = true;
    try {
      let deletedSpace = undefined;
      deletedSpace = await this.evtService.deleteEspace(this.maConfig.monEvenement.id, e.id).toPromise();
      console.log("RETURN ", deletedSpace);
      if (!deletedSpace) {
        this.spaceError = "Erreur : Suppression impossible";
      } else {
        this.spaceError = "Espace correctement supprimé";
        // force full reload cache
        this.reloadSpaces();
      }
    } catch (e) {
      this.spaceError = "Erreur : suppression impossible" + (e.status == 409 ? " (" + e.error + ")" : "") + ".";
      console.log(e.status, " TEXT ", e.statusText)
      console.error(e);
      // this.cdr.detectChanges();
    } finally {
      this.waiting = false;
    }
  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

  async updateSearch() {
    this.spacesDisplay = [];
    this.countSearch = 0;
    this.spaceError = "";

    if (this.searchString && this.searchString.length > 0) {
        // Supprimer les espaces blancs et caractères spéciaux de la recherche
        const sanitizedSearch = this.searchString
            .toLowerCase() // Convertir en minuscules
            .replace(/[\s./\-_^,;:"']/g, ""); // Supprimer espaces et caractères spéciaux

        this.spaces.forEach((e) => {
            // Supprimer les caractères spéciaux pour les champs aussi
            const sanitizedNom = e.nom.toLowerCase().replace(/[\s./\-_^,;:"']/g, "");
            const sanitizedEnseigne = e.enseigne.toLowerCase().replace(/[\s./\-_^,;:"']/g, "");
            const sanitizedNumeroStand = e.numeroStand.toLowerCase().replace(/[\s./\-_^,;:"']/g, "");
            const sanitizedHall = e.hall.toLowerCase().replace(/[\s./\-_^,;:"']/g, "");

            const shouldShow = sanitizedNom.indexOf(sanitizedSearch) > -1
                || sanitizedEnseigne.indexOf(sanitizedSearch) > -1
                || sanitizedNumeroStand.indexOf(sanitizedSearch) > -1
                || sanitizedHall.indexOf(sanitizedSearch) > -1;

            if (shouldShow) {
                this.countSearch++;
                this.spacesDisplay.push(e);
            }
        });
    }
  }

  async loadSpaces() {
    let start = Date.now();
    this.loading = true;
    console.log(" selectSpace loadSpaces -- START ");

    if (!localStorage.getItem("SpaceList")) {
      // aucun cache existant on va chercher les données
      await this.reloadSpaces();
    } else {
      // verifier l'age du cache 
      const listAge = Date.now() - parseInt(localStorage.getItem("SpaceUpdate"));
      if (listAge <= 86400000) {
        // cache valide on le charge
        this.spaces = JSON.parse(localStorage.getItem("SpaceList"));
        // this.countSearch = this.spaces.length;
        this.loading = false;
      } else {
        // cache trop vieux on le recharge (plus de 24h)
        await this.reloadSpaces();
      }
    }
    console.log(" selectSpace loadSpaces -- DONE ", Date.now() - start);
    // this.displayListSpaces(undefined);
  }

  async reloadSpaces() {
    this.loading = true;
    this.spaces = await this.evtService.getSpacesForEvent(this.maConfig.monEvenement.id).toPromise();
    localStorage.setItem("SpaceList", JSON.stringify(this.spaces));
    localStorage.setItem("SpaceUpdate", Date.now().toString());
    this.countSearch = 0;
    this.loading = false;
    this.cdr.detectChanges();
  }

  displayListSpaces(event) {
    console.log(" displayListSpaces ", event);
    if (!this.spacesDisplay) {
      this.spacesDisplay = [];
    }
    const startIdx = this.spacesDisplay.length;
    // for (let i = startIdx; i < this.spaces.length && i < startIdx + 20; i++) {
    for (let i = startIdx; i < this.spaces.length && i < startIdx + 20; i++) {
      this.spacesDisplay.push(this.spaces[i]);
    }
    if (event) {
      event.target.complete();
      console.log(" displayListSpaces COMPLETE");

    }
  }

  async initView() {
    this.maConfig = await this.configService.getConfig();
    await this.loadSpaces();
  }

  ngOnInit() {
    // this.initView();
  }
};

