/**
 * EventHelper API
 * API fournie par NetExpo liée à la gestion interne des événements
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TypeNotification } from './typeNotification';

export interface Notification { 
    id?: number;
    eventId?: number;
    userId?: number;
    requestId?: number;
    creation?: string;
    sms?: string;
    email?: string;
    type?: TypeNotification;
    message?: string;
    seen?: string;
}