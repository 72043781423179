import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@sentry/angular';
import { CommonNetexpoModule } from '../common-netexpo.module';

@Pipe({
  name: 'pipeUserPhoto',
})
export class pipeUserPhoto implements PipeTransform {
  constructor(private common : CommonNetexpoModule) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const user = value as User;
    const userPhotoData =user.photo?.data
    if(userPhotoData){
      user.photo = this.common.arrayBufferToBase64(userPhotoData)
    }
    return user?.photo && user?.photo.length ? true : false;
  }
}
