import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { UserBlockComponent } from './user-block/user-block.component';
import { StatutDemande } from '../server/model/statutDemande';
import { QRCodeModule } from 'angularx-qrcode';
import { ChatComponent } from './chat/chat.component';
import { Attributes, IntersectionObserverHooks, LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { SecuredImagePipe } from '../secured-image.pipe';
import { environment } from 'src/environments/environment';
import { pipeUserPhoto } from './pipes/userPhoto.pipe';
import { pipeUserGeneric } from './pipes/userGeneric.pipe';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  // Change the setup hook with your own default settings
  setup(attributes: Attributes) {
    // attributes.offset = 10;
    attributes.defaultImagePath = 'assets/img_default.svg';
    attributes.errorImagePath = 'assets/img_error.svg';
    return super.setup(attributes);
  }

  loadImage({ imagePath }: Attributes): Promise<string> {
    let headers = {};
    if (imagePath.indexOf(environment.API_BASE_PATH) !== -1) {
      if (localStorage && localStorage.hasOwnProperty('Token')) {
        const userToken = localStorage.getItem('Token');
        headers['Authorization'] = `Bearer ${userToken}`;
      }
    }
    return fetch(imagePath, { headers })
      .then((res) => res.blob())
      .then((blob) => URL.createObjectURL(blob));
  }

}
@NgModule({
  declarations: [pipeUserPhoto,
    pipeUserGeneric,
    HeaderComponent,
    FooterComponent,
    UserBlockComponent,
    ChatComponent,
    SecuredImagePipe
  ],
  imports: [
    QRCodeModule,
    IonicModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    LazyLoadImageModule,
    CommonModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    UserBlockComponent,
    ChatComponent,
  ],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }]
})

export class CommonNetexpoModule {
  // formatDate(dateString: string, full: boolean = false): string {
  //   const date = new Date(dateString);
  //   const result = date.getDate().toString().padStart(2, '0')
  //     + "/" + (date.getMonth() + 1).toString().padStart(2, '0')
  //     + (full ? "/" + date.getFullYear() : "")
  //     + " - " + date.getHours().toString().padStart(2, '0')
  //     + ":" + date.getMinutes().toString().padStart(2, '0')
  //     + (full ? ":" + date.getSeconds().toString().padStart(2, '0') : "");
  //   return result;
  // }

  constructor(private translate: TranslateService) {
  }
  arrayBufferToBase64(buffer):string {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  formatDate(dateString: string, full: boolean = false): string {
    const date = new Date(dateString);
    const result = date.getDate().toString().padStart(2, '0')
      + "/" + (date.getMonth() + 1).toString().padStart(2, '0')
      + (full ? "/" + date.getFullYear() : "")
      + " - " + date.getHours().toString().padStart(2, '0')
      + ":" + date.getMinutes().toString().padStart(2, '0')
      + (full ? ":" + date.getSeconds().toString().padStart(2, '0') : "");
    return result;
  }

  formatDateISO(dateString: string): string {
    const date = new Date(dateString);
    const result = date.getFullYear().toString().padStart(4, '0') + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0')
      + "T" + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0') + ".000Z";
    return result;
  }


  formatHeure(dateString: string): string {
    const date = new Date(dateString);
    const result = date.getHours().toString().padStart(2, '0')
      + ":" + date.getMinutes().toString().padStart(2, '0');
    return result;
  }

  isEmail(email: string) {
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
  }

  isMobile(phone: string) {
    if (phone?.length > 3 && phone?.substring(0, 3) == "+33" && (phone?.substring(3, 4) != "6" && phone?.substring(3, 4) != "7")) {
      return false;
    }
    const regex = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;
    return regex.test(phone);
  }

  formatMobilePhone(phone: string): string {
    // si le téléphone ne commence pas par + fait 10 caractères et commence par un 0 on le transforme en international +33
    if (phone.length == 10 && phone.charAt(0) !== '+' && phone.charAt(0) == '0') {
      phone = '+33' + phone.substring(1);
    }
    return phone;
  }

  waitSeconds(seconds: number) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  duree(dateString: string, type: string = 'list'): string {
    const target = new Date(dateString);
    let now = new Date();
    let res = this.translate.instant("common.since");
    // console.log (" ENTRE ",dateString," NOW ",now.toLocaleDateString(),now.toLocaleTimeString());
    let sec, min, hour, day = 0;
    let tmp = Date.now() - target.getTime();
    tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
    sec = tmp % 60;                    // Extraction du nombre de secondes
    tmp = Math.floor((tmp - sec) / 60);    // Nombre de minutes (partie entière)
    min = tmp % 60;                    // Extraction du nombre de minutes
    tmp = Math.floor((tmp - min) / 60);    // Nombre d'heures (entières)
    hour = tmp % 24;                   // Extraction du nombre d'heures
    tmp = Math.floor((tmp - hour) / 24);   // Nombre de jours restants
    day = tmp;
    if (day < 0 || hour < 0) {
      res = this.translate.instant("common.in") + " ";
      hour = -hour;
      day = -day;
    } else {
      res = this.translate.instant("common.since") + " ";
    }
    // console.log (" diff ",sec,"s ",min,"m ",hour,"h ",day,"j ");

    if (type == "chrono") {
      if (day == 0 && hour <= now.getHours()) {
        res = this.translate.instant("common.today");
      } else if (day == 1 || (day == 0 && hour > now.getHours())) {
        res = this.translate.instant("common.yesterday");
      } else if (day == 2) {
        res = this.translate.instant("common.befyesterday");
      } else if (day > 2 && day < 7) {
        const weekdays = [this.translate.instant("common.sunday"), this.translate.instant("common.monday"), this.translate.instant("common.tuesday"), this.translate.instant("common.wednesday"), this.translate.instant("common.thursday"), this.translate.instant("common.friday"), this.translate.instant("common.saturday")];
        res = weekdays[target.getDay()];
      } else if (now.getDate() > 7) {
        res = this.translate.instant("common.thisMonth");
      } else {
        res = (day > 30 ? this.translate.instant("common.moreMonth") : this.translate.instant("common.lastMonth"));
      }

      // console.log (dateString," Chrono ",day,res);
    } else {
      if (day == 0) {
        if (hour < 1) {
          res = (min < 1 ? this.translate.instant("common.justNow") : res + min.toString() + "'");
        } else {
          res += hour.toString() + "h";
        }
      } else if (day == 1) {
        res += this.translate.instant("common.yesterday");
      } else if (day == -1) {
        res += this.translate.instant("common.tomorrow");
      } else {
        res += day.toString() + this.translate.instant("common.d");
      }
      if (type == "msg") {
        res = res.replace(this.translate.instant("common.since") + " ", '').replace(this.translate.instant("common.in") + " ", '');
      }
    }
    // console.log(" duree ", dateString, type, " => ", res);
    return res;
  }

  formatSimpleDate(dateString: string): string {
    const date = new Date(dateString);
    const result = date.getDate().toString().padStart(2, '0')
      + "/" + (date.getMonth() + 1).toString().padStart(2, '0')
    return result;

  }

  statusColor(status: StatutDemande): string {
    switch (status) {
      case StatutDemande.Newdemand:
        return "danger";
      case StatutDemande.Validated:
        return "warning";
      case StatutDemande.Aborted:
        return "tertiary";
      case StatutDemande.Closed:
        return "success";
      case StatutDemande.Confirmed:
        return "warning";
      case StatutDemande.Affected:
        return "warning";
    }
  }

  readFile(file): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result.toString());
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

}


